import { Button, TextField } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import CustTable from '../comp/custtable/custtable';
import RefreshIcon from '@mui/icons-material/Refresh';
import BrandAdd from './brandadd';

const Brands = ({ token }) => {
    const [items, setItems] = useState([]);
    const [[start, end], setSEnd] = useState([0, 10])
    const [sstr, setSearch] = useState("");
    const [allcont, setAllCount] = useState(0);
    const [loading, setLoad] = useState(true);
    const [addmodal, setAddModal] = useState(false);

    const brandnameref = useRef();

    const columns = [
        { field: 'id', headerName: 'Дугаар', },
        { field: 'brand_name', headerName: 'Брэнд нэр', width: 200 },
        
    ];

    useEffect(() => {
        var url = 'https://wms-cloud.com/api/Brand?_start='+start+'&_end='+end;
        if(sstr!="")
        {
            url=url+'&brand_name=' + sstr;
        }
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    console.log(response.headers.get('X-Total-Count'));
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    localStorage.removeItem('tok');
                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            setItems(resp);
            setLoad(false);
        });

    }, [start, end, sstr])

    const handleRow = (e) => {
        console.log(e);
    }

    const handlePage = (e) => {
        setLoad(true);
        setSEnd([e * 20, (e * 20) + 20]);
    }

    const brandnameChange = (e) =>{
        if (e.keyCode == 13) {
        setSearch(brandnameref.current.value);
        }
    }
    const custRefresh = () => {
        window.location.reload(false);
    }



    return <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className="parenthead">
            <div>
                <h3>Брэнд</h3>
            </div>
            <div style={{display: "flex", width:"100%", justifyContent:"flex-end"}}>
                    <TextField style={{ marginLeft: "3px", marginRight: "3px" }} inputRef={brandnameref}  size="small" label="Нэрээр хайх"  onKeyDown={brandnameChange} />
                    <Button style={{ marginRight: "3px", height: "40px" , marginLeft: "3px", marginRight: "3px"}} variant="outlined" onClick={setAddModal} >Брэнд үүсгэх</Button>
            </div>
            <Button variant="outlined" style={{ marginLeft: "3px", height: "40px" }} onClick={() => {
                window.location.reload(false);
            }}> <RefreshIcon /> </Button>
        </div>
        <div className="tablecont">
            <CustTable
                name={"Brands"}
                columns={columns}
                items={items}
                allcont={allcont}
                handlePage={handlePage}
                rowClick={handleRow}
                loading={loading} />
        </div>
        {addmodal ?
        <BrandAdd handleClose={setAddModal}
        token={token}
        open={addmodal}
        handleRefresh={custRefresh}
        handleModal={setAddModal} /> : <></>
    }
    </div>

}

export default Brands;