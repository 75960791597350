import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { Button, IconButton } from '@mui/material';




export default function MainHeader({ side, handleSide, setToken }) {
    const mClick = (s) => {
        console.log("clicked " + s);
        if (side) {
            handleSide(false);
        } else {
            handleSide(true);
        }

    }

    const handLogout = () => {
        console.log("logout");
        localStorage.clear();
        setToken("");
        // console.log(JSON.stringify(localStorage.getItem('token')));

    }


    return <div className="header">
        {/* {!side?"WMS":<></>} */}
        <div>
            <IconButton onClick={() => mClick(side, handleSide)}> <MenuIcon /> </IconButton>
            Хэрэглэгчийн нэр: {localStorage.getItem('uname')}
        </div>
        <div>

            <Button onClick={handLogout}>Системээс Гарах</Button>
        </div>
    </div>
}