import React, { useState, useEffect, useRef } from 'react';
import CustTable from '../comp/custtable/custtable';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Stack from '@mui/material/Stack';
import * as XLSX from 'xlsx';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { format } from 'date-fns';
import CustomerNameAuto from '../comp/custnameauto';
import RefreshIcon from '@mui/icons-material/Refresh';
import dayjs from 'dayjs';

const OrderGroupRep = ({ token }) => {

    const [data, setData] = useState([]);
    const [allcount, setAllCount] = useState([]);
    const [loading, setLoad] = useState(true);
    const [[start, end], setSEnd] = useState([0, 20]);

    const [startdate, setStartDate] = React.useState(dayjs());
    const [stat, setStat] = useState("");
    const [loanstat, setLoanStat] = useState("");
    const [actstart, setActStart] = React.useState();
    const [enddate, setEndDate] = React.useState(dayjs());
    const [actend, setActEnd] = React.useState();
    const [emp_id, setEmp_id] = useState("");
    const [subrow, setSubrow] = useState(false);
    const [zahdugaar, setZahdugaar] = useState("");
    const [selected, setSelected] = useState({ cust_id: "Customer", cust_name: "Customer" });
    const [options, setOptions] = useState([]);

    const searchemp_id = useRef();
    const searchzahdugaar = useRef();



    const columns = [
        { field: 'id', headerName: 'Зах/Дугаар', },
        { field: 'txn_date', headerName: 'Огноо', },
        { field: 'txn_cust_id', headerName: 'Харилцагч код', },
        { field: 'cust_name', headerName: 'Харилцагч нэр',  width: 250  },
        { field: 'cust_reg_num', headerName: 'Регистр',  width: 120},
        { type: 'number', field: 'zah_dun', headerName: 'Захиалга дүн', width: 120},
        { type: 'number', field: 'bonus', headerName: 'Хөнгөлөлт', },
        { type: 'number', field: 'tuluh_dun', headerName: 'Төлөх дүн', width: 120},
        { field: 'ord_tuluh_helber', headerName: 'Төлөх хэлбэр', },
        { field: 'ord_status', headerName: 'Төлөв',  width: 150},
        { field: 'txn_emp_id', headerName: 'ХТ КОД', },
        { field: 'emp_firstname', headerName: 'ХТ Нэр', },
        { field: 'la_status', headerName: 'Зээлийн төлөв',  width: 150},
        { type: 'number', field: 'la_balance', headerName: 'Зээлийн дүн', },
        { type: 'number', field: 'la_paid_amount', headerName: 'Төлсөн дүн', },
        { type: 'number', field: 'la_uldegdel', headerName: 'Үлдэгдэл', }
    ];

    const getdataforname = (e) => {
        // console.log("changevallen" + e.target.value);
        // if (e.target.value.length > 0) {
        var tok = localStorage.getItem("tok");
        var url = "";
        if (e == null) {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + selected.cust_name;

        } else {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + e.target.value;
        }
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&prod_code=' + e.target.value;
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=5000';
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });

        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log("from here 2");
            // console.log(JSON.stringify(resp));
            var temp = [];
            resp.map((e) => {
                temp.push({ "cust_id": e.cust_id, "cust_name": e.cust_name });
            });
            // console.log("temp:" + JSON.stringify(temp));
            setOptions(temp);
        });
        // }
    }

    const downloadExcel = () => {
        var url = 'https://wms-cloud.com/api/OrderGrpReport?_start=0&_end=10000';
        //var url = 'http://localhost:8080/OrderGrpReport?_start=0&_end=10000';
        if (stat != "") {
            url = url + "&status=" + stat;
        }
        if (emp_id != "") {
            url = url + "&emp_id=" + emp_id;
        }
        if (zahdugaar != "") {
            url = url + "&zahdugaar=" + zahdugaar;
        }
        if (selected.cust_id != "Customer") {
            url = url + '&cust_id=' + selected.cust_id;
        }
        if (actstart != undefined) {
            // console(format(startdate, 'yyyy-MM-DD'));
            url = url + '&from_date=' + actstart;
            
        }
        if (loanstat != "") {
            url = url + "&la_status=" + loanstat;
        }

        if (actend != undefined) {
            url = url + '&to_date=' + actend;
            
        }
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        console.log(
            JSON.stringify(request)
        );
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    localStorage.removeItem('tok');
                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            setLoad(false);

            const workSheet = XLSX.utils.json_to_sheet(resp);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, "OrderGrpReport");
            //Buffer
            let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
            //Binary string
            XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
            //Download
            XLSX.writeFile(workBook, "OrderGrpReport" + ".xlsx");

        });
    };

    useEffect(() => {
        var url = 'https://wms-cloud.com/api/OrderGrpReport?_start='+start+'&_end='+end;
        //var url = 'http://localhost:8080/OrderGrpReport?_start='+start+'&_end='+end;
        if (stat != "") {
            url = url + "&status=" + stat;
        }
        if (loanstat != "") {
            url = url + "&la_status=" + loanstat;
        }
        if (emp_id != "") {
            url = url + "&emp_id=" + emp_id;
        }
        if (zahdugaar != "") {
            url = url + "&zahdugaar=" + zahdugaar;
        }
        if (selected.cust_id != "Customer") {
            url = url + '&cust_id=' + selected.cust_id;
        }
        if (actstart != undefined) {
            // console(format(startdate, 'yyyy-MM-DD'));
            url = url + '&from_date=' + actstart;
            
        }

        if (actend != undefined) {
            url = url + '&to_date=' + actend;
            
        }
        
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        console.log(
            JSON.stringify(request)
        );
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    localStorage.removeItem('tok');
                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            setData(resp);
            setLoad(false);
        });
    }, [start, end, startdate, stat, loanstat, emp_id, zahdugaar, selected, enddate]);

    const handleChange = (e) => {
        setLoad(true);
        setStat(e.target.value);
    }

    const handleLoanChange = (e) => {
        setLoad(true);
        setLoanStat(e.target.value);
    }

    const handleStartChange = (e) => {
        setLoad(true);
        var t = new Date(e);
        var d = format(t, 'yyyy-MM-dd');
        console.log("e: " + e + " : " + t + " : " + d);
        setStartDate(e);
        setActStart(d);
    }

    const handleEndChange = (e) => {
        setLoad(true);
        var t = new Date(e);
        var d = format(t, 'yyyy-MM-dd');
        setEndDate(e);
        setActEnd(d);
    }

    const emp_idChange = (e) =>{
        if (e.keyCode == 13) {
        setEmp_id(searchemp_id.current.value);
        }
    }
    const zahdugaarChange = (e) =>{
        if (e.keyCode == 13) {
        setZahdugaar(searchzahdugaar.current.value);
        }
    }

    const handlePage = (e) => {
        setSEnd([e * 20, (e * 20) + 20]);
    }

    return <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className="parenthead">
            <div>
                <h3>Захиалга нэгтгэл тайлан</h3>
            </div>
            <div style={{ display: "flex" }}>
                <div style={{ width: "150px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Эхлэх огноо"
                                inputFormat="YYYY-MM-DD"
                                value={startdate}
                                onChange={handleStartChange}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </div>
                <div style={{ width: "10px" }}></div>
                <div style={{ width: "150px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Дуусах огноо"
                                inputFormat="YYYY-MM-DD"
                                value={enddate}
                                onChange={handleEndChange}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </div>
                <div style={{ width: "200px", marginRight: "3px" }}>
                    {/* <TextField style={{ marginLeft: "3px" }} inputRef={searchcust_id}  size="small" label="Харилцагч" onKeyDown={cust_idChange} /> */}   
                    <CustomerNameAuto selected={selected} setProd={setSelected} options={options} getdataforbar={getdataforname}/>
                </div>
                <div style={{ width: "120px", marginRight: "3px" }}>
                    <TextField style={{ marginLeft: "3px" }} inputRef={searchemp_id}  size="small" label="Борлуулагч" onKeyDown={emp_idChange} />
                </div>
                <div style={{ width: "120px" }}>
                    {/* <TextField style={{ marginLeft: "3px" }} size="small" label="Status" onKeyDown={() => { }} /> */}
                    <FormControl fullWidth size="small" >
                        <InputLabel id="demo-simple-select-label">Төлөв</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={stat}
                            label="Status"
                            onChange={handleChange}
                        >
                            {/* {items.map(e => <MenuItem key={e.id} value={e.id}>{e.brand_name}</MenuItem>)} */}
                            <MenuItem key={"Зарлага гаргасан"} value={"Зарлага гаргасан"}>{"Зарлага гаргасан"}</MenuItem>)
                            <MenuItem key={"Хүргэлт хийсэн"} value={"Хүргэлт хийсэн"}>{"Хүргэлт хийсэн"}</MenuItem>)
                            <MenuItem key={"Төлөгдсөн"} value={"Төлөгдсөн"}>{"Төлөгдсөн"}</MenuItem>)
                        </Select>
                    </FormControl>
                </div>
                <div style={{ width: "120px" }}>
                    {/* <TextField style={{ marginLeft: "3px" }} size="small" label="Status" onKeyDown={() => { }} /> */}
                    <FormControl fullWidth size="small" >
                        <InputLabel id="demo-simple-select-label">Зээлийн төлөв</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={loanstat}
                            label="LoanStatus"
                            onChange={handleLoanChange}
                        >
                            {/* {items.map(e => <MenuItem key={e.id} value={e.id}>{e.brand_name}</MenuItem>)} */}
                            <MenuItem key={"Бүгд"} value={""}>{"Бүгд"}</MenuItem>
                            <MenuItem key={"Төлөгдсөн"} value={"Төлөгдсөн"}>{"Төлөгдсөн"}</MenuItem>)
                            <MenuItem key={"Төлөгдөөгүй"} value={"Төлөгдөөгүй"}>{"Төлөгдөөгүй"}</MenuItem>)
                        </Select>
                    </FormControl>
                </div>
                <div style={{ width: "150px" }}>
                    <TextField style={{ marginLeft: "3px" }} inputRef={searchzahdugaar}  size="small" label="Баримт дугаар" onKeyDown={zahdugaarChange} />
                </div>
                <Button style={{ marginRight: "3px", height: "40px" }} onClick={downloadExcel} variant="outlined">Excel -ээр татах</Button>
                <Button style={{ marginLeft: "3px", height: "40px" }} variant="outlined" onClick={() => {
                    // setStat("");
                    window.location.reload(false);
                    localStorage.setItem('localbarcode',"");
                    localStorage.setItem('localemp_id',"");
                    localStorage.setItem('localzahdugaar',"");
                    localStorage.setItem('localstat', "");
                    localStorage.setItem('localCustidSelect',  "Customer");
                    
                }}> <RefreshIcon /> </Button>
            </div>

        </div>
        <div className="tablecont">
            <CustTable
                name={"Order Daily Report"}
                exportname={"order_daily_report"}
                columns={columns}
                items={data}
                allcont={allcount}
                handlePage={handlePage}
                loading={loading}
                cellname={"prod_name"}
                checkboxselect={false}
            // handlePage={}
            />
        </div>
    </div>

}
export default OrderGroupRep;