import { Autocomplete, Box, TextField } from "@mui/material";

const CustomerNameAuto = ({ selected, setProd, getdataforbar, options }) => {

    return <div style={{ marginLeft: "3px" }}>
    <Autocomplete
        options={options}
        value={selected}
        onInputChange={getdataforbar}
        onChange={(e, value) => {
            console.log(value.cust_name);
            setProd(value);
        }}

        getOptionLabel={option => option.cust_name}

        renderOption={(props, option) => (<Box  component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

            {option.cust_name}
        </Box>)
        }
        renderInput={(params) => (
            <TextField 
                {...params} label="Харилцагч нэрээр хайх" variant="outlined" style={{ marginLeft: "5px" }} size="small" />
        )} />
</div >
}


export default CustomerNameAuto