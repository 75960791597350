import { Button, CircularProgress, Divider, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ProductBarcodeAuto from "../comp/prodbarcodeauto";
import ProductAuto from "../comp/prodbarcodeauto";
import ProductNameAuto from "../comp/prodnameauto";




const OrderUpdate = ({ ordid, handleClose, custrow, handleRefresh }) => {
    var tok = localStorage.getItem("tok");
    const [prodload, setProdLoad] = useState(false);
    const [selected, setSelected] = useState({ prod_code: custrow.ordet_prod_code, prod_name: custrow.ordet_prod_name });
    const [currprod, setCurrProduct] = useState([]);
    const [options, setOptions] = useState([]);
    const ordet_id = useRef();
    const orddet_ord_id = useRef();
    const ordet_prod_code = useRef();
    const ordet_prod_name = useRef();
    const ordet_too = useRef();
    const ordet_price = useRef();
    const ordet_prod_price = useRef();
    const ordet_cre_time = useRef();


    // option 1 eer yvuul lalaraa

    console.log(custrow);
    console.log("selected from update:" + JSON.stringify(selected));


    const handleSubmit = () => {
        setProdLoad(true);
        var req = {
            "ordet_id": custrow.ordet_id,
            "ordet_ord_id": custrow.ordet_ord_id,
            "ordet_option": 1,
            "ordet_prod_code": selected.prod_code,
            "ordet_prod_name": selected.prod_name,
            "ordet_too": ordet_too.current.value,
            "ordet_price": ordet_price.current.value,

        };

        const request = new Request('https://wms-cloud.com/api/OrderdetailsUpdate/', {
            method: 'POST',
            body: JSON.stringify(req),
            headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${tok}` })
        });

        fetch(request).then((response) => {
            if (response.status == 200) {
                console.log("amjilttai " + JSON.stringify(response));
                setProdLoad(false);
                return response.json();

            } else {
                console.log("Амжилтгүй: " + response.status);
                setProdLoad(false);
            }
        }).then(resp => {
            console.log(JSON.stringify(resp));

            alert("Амжилттай: " + resp.ordet_prod_name);
            setProdLoad(false);

            handleRefresh();
        });

        console.log("create req:" + JSON.stringify(req));

    }

    const handleClick = () => {
        handleClose(false);
    }

    const handleEscape = (e) => {
        // console.log(e);
        if (e.keyCode == 27) {
            // console.log("escape clicked");
            handleClose(false);
        }

    }

    const setProdtoshow = (e) => {
        setCurrProduct(e);
    }

    const getdataforname = (e) => {
        // console.log("changevallen" + e.target.value);
        // if (e.target.value.length > 0) {
        var tok = localStorage.getItem("tok");
        var url = "";
        if (e == null) {
            url = 'https://wms-cloud.com/api/Products?_start=0&_end=1000&prod_name=' + selected.prod_name;

        } else {
            url = 'https://wms-cloud.com/api/Products?_start=0&_end=1000&prod_name=' + e.target.value;
        }
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&prod_code=' + e.target.value;
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=5000';
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });

        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log("from here 2");
            // console.log(JSON.stringify(resp));
            var temp = [];
            resp.map((e) => {
                temp.push({ "prod_code": e.prod_code, "prod_name": e.prod_name, "prod_price": e.prod_price, "prod_uldegdel": e.prod_uldegdel });
            });
            // console.log("temp:" + JSON.stringify(temp));
            setOptions(temp);
        });
        // }
    }

    const getdataforbar = (e) => {
        console.log("changevallen" + e);
        // if (e.target.value.length > 0) {
        var tok = localStorage.getItem("tok");

        var url = "";
        if (e == null) {
            console.log("e null");
            url = 'https://wms-cloud.com/api/Products?_start=0&_end=1000&q=' + selected.prod_code;

        } else {
            url = 'https://wms-cloud.com/api/Products?_start=0&_end=1000&q=' + e.target.value;
        }
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=1000&prod_name=' + e.target.value;
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&q=' + selected.prod_code;
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=5000';
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });

        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log("from here 2");
            // console.log(JSON.stringify(resp));
            var temp = [];
            resp.map((e) => {
                temp.push({ "prod_code": e.prod_code, "prod_name": e.prod_name, "prod_price": e.prod_price, "prod_uldegdel": e.prod_uldegdel });
            });
            // console.log("temp:" + JSON.stringify(temp));
            setOptions(temp);
        });
        // }
    }

    useEffect(() => {
        // document.addEventListener(KEY_EVENT_TYPE, handleEscape, false);
        console.log("OrderUpdate: " + JSON.stringify(selected));

        ordet_id.current.value = custrow.ordet_id;
        orddet_ord_id.current.value = custrow.ordet_ord_id;
        ordet_too.current.value = custrow.ordet_too;
        ordet_price.current.value = custrow.ordet_price;
        ordet_prod_price.current.value = custrow.ordet_prod_price;


        if (selected != undefined) {
            console.log("before getting: " + selected.prod_code)
            // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=1000&prod_name=' + e.target.value;
            var url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&id=' + selected.prod_code;
            console.log("url: " + url);
            // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=1000';
            const request = new Request(url, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tok}`,
                    
                    'Access-Control-Allow-Headers': 'X-Total-Count'
                })
            });

            fetch(request).then(
                (response) => {
                    if (response.status == 200) {
                        return response.json();
                    } else if (response.status == 500) {
                        alert("Алдаа гарлаа дахин оролдоно уу.");
                    } else {
                        // localStorage.getItem('tok')
                        localStorage.removeItem('tok');

                        window.location.reload(false);
                        alert("Алдаа гарлаа.")
                    }
                }
            ).then((resp) => {
                // var temp = [];
                // resp.map((e) => {
                //     temp.push({ "prod_code": e.prod_code, "prod_name": e.prod_name });
                // });
                // console.log("temp:" + JSON.stringify(temp));
                // setOptions(temp);

            });
        }

        // esc window listerner
        window.addEventListener('keyup', handleEscape);
        return () => {
            window.removeEventListener('keyup', handleEscape);
        }


    }, [handleEscape, selected]);






    return <div className="custmodal" >
        <div className="mcont">
            <div className="mheader">
                Захиалга дахь бараа засах
        <button onClick={() => handleClick()}>x</button>
            </div>
            <Divider />
            <div className="mccont">
                <div className="crow">
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={ordet_id} label="Код" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={orddet_ord_id} label="Захиалгын дугаар" fullWidth disabled={true}></TextField>
                    </div>

                </div>

                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        {/* <TextField label="Product Id" fullWidth ></TextField> */}
                        <ProductBarcodeAuto selected={selected} setProd={setSelected} getdataforbar={getdataforbar} options={options} />
                        <div>{selected.prod_price!=undefined?" Баркод: "+selected.prod_code+" Нэр: "+selected.prod_name+" "+"Үнэ: "+selected.prod_price+" "+"Үлдэгдэл: "+selected.prod_uldegdel:""}</div>
                    </div>
                </div>
                {/* <Divider className="divide" /> */}
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        {/* <TextField label="Product Id" fullWidth ></TextField> */}
                        {/* <TextField label="Product Name" fullWidth ></TextField> */}
                        <ProductNameAuto selected={selected} setProd={setSelected} options={options} getdataforbar={getdataforname} />
                        <div>{selected.prod_price!=undefined?" Баркод: "+selected.prod_code+" Нэр: "+selected.prod_name+" "+"Үнэ: "+selected.prod_price+" "+"Үлдэгдэл: "+selected.prod_uldegdel:""}</div>
                    </div>
                </div>

                {/* <Divider className="divide" /> */}
                <div className="crow">
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={ordet_too} label="Too" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={ordet_prod_price} label="Бүтээгдхүүний үнэ" defaultValue={ordid} fullWidth disabled={true}></TextField>
                    </div>

                </div>
                <div className="crow">
                    <div style={{ width: "50%", paddingRight: "10px", flex: '1' }}>

                        <TextField inputRef={ordet_price} fullWidth label='Бүтээгдхүүний үнэ' InputLabelProps={{ shrink: true }} defaultValue="Үнэ" disabled={true}></TextField>
                    </div>
                    {/* <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={ordet_too} InputLabelProps={{ shrink: true }} label="Тоо" fullWidth disabled={true}></TextField>
                    </div> */}
                    {/* <div style={{ width: "30%", paddingRight: "10px" }}>
                        <TextField inputRef={prod_av_bal} label="Prodcut Av/Balance" fullWidth InputLabelProps={{ shrink: true }} ></TextField>
                    </div> */}
                </div>



            </div>
            <div className="mfooter">
                <Button onClick={handleSubmit}>Хадгалах</Button>
                <Button onClick={handleClick}>Буцах</Button>
                {/* <button>Save</button> */}
                {/* <button>Cancel</button> */}
            </div>
            {prodload ?
                <div className="prodload">
                    <CircularProgress />
                </div> : ""}
        </div>
    </div>
}

export default OrderUpdate;