import { Button, CircularProgress, Divider, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import ProductBarcodeAuto from "../comp/prodbarcodeauto";
import ProductAuto from "../comp/prodbarcodeauto";
import ProductNameAuto from "../comp/prodnameauto";




const OrderModal = ({ ordid, handleClose, handleRefresh }) => {
    var tok = localStorage.getItem("tok");
    const [prodload, setProdLoad] = useState(false);
    const [selected, setSelected] = useState({ prod_code: "Бүтээгдэхүүний Code", prod_name: "Бүтээгдэхүүний Нэр" });
    const [currprod, setCurrProduct] = useState([]);
    const [options, setOptions] = useState([]);
    // const options = [
    //     { prod_name: "The Shawshank Redemption", prod_code: 1994 },
    //     { prod_name: "The Godfather", prod_code: 1972 },
    //     { prod_name: "The Godfather: Part II", prod_code: 1974 },
    //     { prod_name: "The Dark Knight", prod_code: 2008 }
    // ];
    const [inputVal, setInptVal] = useState({});
    const ord_too = useRef();
    // const ordid = useRef();
    const prod_price = useRef();
    const prod_uld = useRef();
    const prod_av_bal = useRef();
    // const [ord_too, setOrdToo] = useState();
    // const [ordid, setOrdId] = useState();
    // const [prod_price, setProdPrice] = useState();
    // const [prod_uld, setProdUld] = useState();
    // const [prod_av_bal, setAvBal] = useState();


    // option 1 eer yvuul lalaraa

    const handleSubmit = () => {
        setProdLoad(true);
        var req = {
            "ordet_id": "",
            "ordet_ord_id": ordid,
            "ordet_option": 2,
            "ordet_prod_code": selected.prod_code,
            "ordet_prod_name": selected.prod_name,
            "ordet_too": ord_too.current.value,
            "ordet_price": prod_price.current.value,

        };

        const request = new Request('https://wms-cloud.com/api/OrderdetailsUpdate/', {
            method: 'POST',
            body: JSON.stringify(req),
            headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${tok}` })
        });

        fetch(request).then((response) => {
            if (response.status == 200) {
                console.log("amjilttai " + JSON.stringify(response));
                setProdLoad(false);
                return response.json();

            } else {
                console.log("Амжилтгүй: " + response.status);
                setProdLoad(false);
            }
        }).then(resp => {
            console.log(JSON.stringify(resp));

            alert("Амжилттай: " + resp.ordet_prod_name);
            setProdLoad(false);

            handleRefresh();
        });

        console.log("create req:" + JSON.stringify(req));

    }

    const handleClick = () => {
        handleClose(false);
    }

    const handleEscape = (e) => {
        // console.log(e);
        if (e.keyCode == 27) {
            // console.log("escape clicked");
            handleClose(false);
        }

    }

    const setProdtoshow = (e) => {
        setCurrProduct(e);
    }

    const getdataforname = (e) => {
        // console.log("changevallen" + e.target.value);
        // if (e.target.value.length > 0) {
        var tok = localStorage.getItem("tok");
        var url = "";
        if (e == null) {
            url = 'https://wms-cloud.com/api/Products?_start=0&_end=1000&prod_name=' + selected.prod_name;

        } else {
            url = 'https://wms-cloud.com/api/Products?_start=0&_end=1000&prod_name=' + e.target.value;
        }
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&prod_code=' + e.target.value;
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=5000';
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });

        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log("from here 2");
            // console.log(JSON.stringify(resp));
            var temp = [];
            resp.map((e) => {
                temp.push({ "prod_code": e.prod_code, "prod_name": e.prod_name });
            });
            // console.log("temp:" + JSON.stringify(temp));
            setOptions(temp);
        });
        // }
    }

    const getdataforbar = (e) => {
        console.log("changevallen" + e);
        // if (e.target.value.length > 0) {
        var tok = localStorage.getItem("tok");

        var url = "";
        if (e == null) {
            console.log("e null");
            url = 'https://wms-cloud.com/api/Products?_start=0&_end=1000&q=' + selected.prod_code;

        } else {
            url = 'https://wms-cloud.com/api/Products?_start=0&_end=1000&q=' + e.target.value;
        }
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=1000&prod_name=' + e.target.value;
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&q=' + selected.prod_code;
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=5000';
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });

        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log("from here 2");
            // console.log(JSON.stringify(resp));
            var temp = [];
            resp.map((e) => {
                temp.push({ "prod_code": e.prod_code, "prod_name": e.prod_name });
            });
            // console.log("temp:" + JSON.stringify(temp));
            setOptions(temp);
        });
        // }
    }

    useEffect(() => {
        // document.addEventListener(KEY_EVENT_TYPE, handleEscape, false);
        console.log("ordermodal: " + JSON.stringify(selected));
        // console.log("ordermodal: " + selected.prod_code);





        if (selected != undefined) {
            console.log("before getting: " + selected.prod_code)
            // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=1000&prod_name=' + e.target.value;
            var url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&id=' + selected.prod_code;
            console.log("url: " + url);
            // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=1000';
            const request = new Request(url, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tok}`,
                    
                    'Access-Control-Allow-Headers': 'X-Total-Count'
                })
            });

            fetch(request).then(
                (response) => {
                    if (response.status == 200) {
                        return response.json();
                    } else if (response.status == 500) {
                        alert("Алдаа гарлаа дахин оролдоно уу.");
                    } else {
                        // localStorage.getItem('tok')
                        localStorage.removeItem('tok');

                        window.location.reload(false);
                        alert("Алдаа гарлаа.")
                    }
                }
            ).then((resp) => {
                // console.log("from here");
                // console.log(JSON.stringify(resp));
                // console.log("selectd: from out: " + JSON.stringify(selected));
                prod_price.current.value = resp[0].prod_price;
                prod_uld.current.value = resp[0].prod_uldegdel;
                // prod_av_bal.current.value = resp[0].pr
                // setProdtoshow(resp);
                // setProdPrice(selected[0].prod_price);

            });
        }

        // esc window listerner
        window.addEventListener('keyup', handleEscape);
        return () => {
            window.removeEventListener('keyup', handleEscape);
        }


    }, [handleEscape, selected]);






    return <div className="custmodal" >
        <div className="mcont">
            <div className="mheader">
                Захиалганд бараа нэмэх
        <button onClick={() => handleClick()}>x</button>
            </div>
            <Divider />
            <div className="mccont">

                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        {/* <TextField label="Product Id" fullWidth ></TextField> */}
                        <ProductBarcodeAuto selected={selected} setProd={setSelected} options={options} getdataforbar={getdataforbar} inputVal={inputVal} />
                    </div>
                </div>
                {/* <Divider className="divide" /> */}
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        {/* <TextField label="Product Id" fullWidth ></TextField> */}
                        {/* <TextField label="Product Name" fullWidth ></TextField> */}
                        <ProductNameAuto selected={selected} setProd={setSelected} options={options} getdataforbar={getdataforname} inputVal={inputVal} />
                    </div>
                </div>

                {/* <Divider className="divide" /> */}
                <div className="crow">
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={ord_too} label="Too" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField label="Захиалгын дугаар" defaultValue={ordid} fullWidth disabled={true}></TextField>
                    </div>

                </div>
                <div className="crow">
                    <div style={{ width: "50%", paddingRight: "10px" }}>

                        <TextField inputRef={prod_price} fullWidth label='Бүтээгдхүүний үнэ' InputLabelProps={{ shrink: true }} defaultValue="Үнэ" disabled={true}></TextField>
                    </div>
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={prod_uld} InputLabelProps={{ shrink: true }} label="Бүтээгдхүүний үлдэгдэл" fullWidth defaultValue="Үлдэгдэл" disabled={true}></TextField>
                    </div>
                    {/* <div style={{ width: "30%", paddingRight: "10px" }}>
                        <TextField inputRef={prod_av_bal} label="Prodcut Av/Balance" fullWidth InputLabelProps={{ shrink: true }} ></TextField>
                    </div> */}
                </div>



            </div>
            <div className="mfooter">
                <Button onClick={handleSubmit}>Хадгалах</Button>
                <Button onClick={handleClick}>Буцах</Button>
                {/* <button>Save</button> */}
                {/* <button>Cancel</button> */}
            </div>
            {prodload ?
                <div className="prodload">
                    <CircularProgress />
                </div> : ""}
        </div>
    </div>
}

export default OrderModal;