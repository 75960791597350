import { Button, CircularProgress, Divider,  TextField } from '@mui/material';
import { useRef, useEffect, useState } from 'react';
import '../css/Bonusmod.css'


const BrandAdd = ({ handleClose, token, open, handleModal, handleRefresh }) => {

    const brand_name = useRef();
    const [prodload, setProdload] = useState(false);

    const handleEscape = (e) => {
        if (e.keyCode == 27) {
            handleClose(false);
        }
    }

    useEffect(() => {
        window.addEventListener('keyup', handleEscape);
        return () => {
            window.removeEventListener('keyup', handleEscape);
        }
    }, [handleEscape])

    const handleClick = () => {
        if (open) {
            handleModal(false);
        }
    }

    const handleSubmit = () => {
        setProdload(true);
        var req = {
            'brand_name': brand_name.current.value
        }

        console.log(JSON.stringify(req));

        const request = new Request('https://wms-cloud.com/api/Brand' , {
            method: 'POST',
            body: JSON.stringify(req),
            headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${token}` })
        });

        fetch(request).then((response) => {
            if (response.status == 200) {
                console.log(response.body);
                setProdload(false);
                handleClose(false);
                handleRefresh();

            } else {
                console.log("aldaa garsan" + JSON.stringify(response));
                alert("Амжилтгүй "+ JSON.stringify(response) );
                setProdload(false);
            }
        });
    }

    return <div className="custmodal" >
    <div className="mcont">
        <div className="mheader">
            Brand Нэмэх
        <button onClick={() => handleClick()}>x</button>
        </div>
        <Divider />
        <div className="mccont">
                <div className="crow">
                    <div style={{  width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={brand_name} label="Брэнд нэр" fullWidth ></TextField>
                    </div>
                </div>
        </div>
        <div className="mfooter">
            <Button onClick={handleSubmit}>Хадгалах</Button>
            <Button onClick={handleClick}>Буцах</Button>
        </div>
        {prodload ?
            <div className="prodload">
                <CircularProgress />
            </div> : ""
        }
    </div>
    </div>

}

export default BrandAdd;