import React, { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    LineElement,
    PointElement,
    ArcElement

} from 'chart.js';
import { Bar, Line, Doughnut } from 'react-chartjs-2';
// import faker from 'faker';

const Dashboard = ({ token }) => {
    const [charData, setChart] = useState([]);
    const [compdata1, setData] = useState({
        labels: [1],
        datasets: [{
            fill: true,
            label: 'Orlogiin hemjee',
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            data: [1]
        }, {
            fill: true,
            label: 'Zarlagiin hemjee',
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            data: [1],
        }
        ]
    });

    const [Piechartdata, setPiechartdata] = useState({
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: 'test',
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    });


    ChartJS.register(
        CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend,
        LineElement,
        PointElement,
        ArcElement
    );

    // console.log("data: " + JSON.stringify(compdata1.datasets));
    // console.log("data1: " + JSON.stringify(compdata1.labels));


    const data = {
        labels: charData.label2,
        datasets: [{
            fill: true,
            label: 'Orlogiin hemjee',
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            data: charData.data2
        }, {
            fill: true,
            label: 'Zarlagiin hemjee',
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            data: charData.crdrreportdata,
        }
        ]
    };

    var compdata = {
        labels: compdata1.labels,
        datasets: [{
            fill: true,
            label: 'Orlogiin hemjee',
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
            data: [1]
        }, {
            fill: true,
            label: 'Zarlagiin hemjee',
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.5)',
            data: [1],
        }
        ]
    };


    // console.log("123: " + JSON.stringify(compdata));

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Орлого зарлага',
            },
        },
    };

    const opt = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top'
            },
            title: {
                display: true,
                text: 'Орлого компани',
            },
        },
    };


    const piedata = {
        labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        datasets: [
            {
                label: 'test',
                data: [12, 19, 3, 5, 2, 3],
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    }

    useEffect(() => {

        const request = new Request('https://wms-cloud.com/api/ChartBoard', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        // console.log("token: " + token);
        // console.log(
        //     JSON.stringify(request)
        // );
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    // console.log(response.headers.get('X-Total-Count'));
                    return response.json();
                }
            }
        ).then((resp) => {
            console.log(JSON.stringify(resp))
            // var tempbar = charData;
            var temp = compdata1;
            var tempProd=piedata;
            temp.labels = resp.companyBarData.labels;
            temp.datasets = resp.companyBarData.datasets;
            tempProd.labels=resp.productPielabel;

            const piedatasets= [
                {
                  label: 'Агуулах дахь бүтээгдхүүн',
                  data: resp.productPiedata,
                  backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                  ],
                  borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                  ],
                  borderWidth: 1,
                },
              ];

            tempProd.datasets=piedatasets;

            setPiechartdata(tempProd);


            // tempbar.labels = resp.label2;
            // tempbar.datasets = resp.data2;
            setChart(resp);
            setData(temp);




        });
    }, []);

    return <div style={{ height: "100%", overflowX: "scroll", paddingTop: "10px", display: "flex", flexDirection: "column" }}>
        <div>
            <h3>Хяналтын самбар</h3>
        </div>

        <div style={{
            height: "100%",
            width: "100%",
            display: "flex", gridTemplateColumns: "auto auto",
            flexDirection: "column"
        }}>
            {/* {JSON.stringify(charData.label2)} */}
            <div style={{ display: "flex", flexDirection: "row", flex: "1", marginBottom: "50px" }}>
                <div
                    style={{ width: "50%" }}>
                    <Bar
                        options={options} data={data}
                    // width={300} height={100}
                    />

                </div>
                <div style={{ width: "50%" }}>
                    <Line
                        options={options} data={data}
                    // width={300} height={100} 
                    />
                </div>
            </div>

                <div >
                    <Bar options={opt} data={compdata1} />
                </div>
                <div style={{ flex: "1", width: "50%" }}>
                    <Doughnut data={Piechartdata} width={200} height={100} />
                </div>


        </div>
    </div >
}


export default Dashboard;

