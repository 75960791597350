import { useEffect, useState } from "react";

const { FormControl, InputLabel, Select, MenuItem } = require("@mui/material")

const BrandFilter = ({ token, brand, clear, handleBrand, brandid }) => {
    const [items, setItems] = useState([]);
    const [cbrand, setBrand] = useState("");

    useEffect(() => {
        if (brandid != "" && brandid != undefined) {
            console.log(brandid + " asdasd ");
            setBrand(brandid);
        }
    });

    useEffect(() => {
        // https://wms-cloud.com/api/Brands?_start=0&_end=100
        const request = new Request('https://wms-cloud.com/api/Brand?_start=0&_end=100'
            , {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    
                    'Access-Control-Allow-Headers': 'X-Total-Count'
                })
            });
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    // console.log(response.headers.get('X-Total-Count'));
                    // setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                }
            }
        ).then((resp) => {
            // console.log(JSON.stringify(resp));
            setItems(resp);
            // setLoading(false);

        });
    }, [])

    const handleChange = (e) => {
        console.log("handle change: " + e.target.value);
        setBrand(e.target.value);
        handleBrand(e.target.value);
    }


    return <div style={{ width: "", marginLeft: "5px" }}>
        <FormControl fullWidth >
            <InputLabel id="demo-simple-select-label">Брэнд</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={cbrand}
                label="Brand"
                onChange={handleChange}
            >
                {items.map(e => <MenuItem key={e.id} value={e.id}>{e.brand_name}</MenuItem>)}
            </Select>
        </FormControl>
    </div>
}

export default BrandFilter;