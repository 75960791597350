import { Autocomplete, Box, createFilterOptions, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";

const ProductNameAuto = ({ selected, setProd, getdataforbar, inputVal, options }) => {


    return <div style={{ marginLeft: "3px" }
    }>
        <Autocomplete
            options={options}
            value={selected}
            onInputChange={getdataforbar}
            onChange={(e, value) => {
                console.log(value.prod_code);
                setProd(value);
            }}
            // filterOptions={filterOptions}
            getOptionLabel={option => option.prod_name}
            // getOptionLabel={option => option.prod_code}
            renderOption={(props, option) => (<Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>

                {option.prod_code} {option.prod_name}
            </Box>)
            }
            renderInput={(params) => (
                <TextField
                    // {...input}
                    {...params} label="Бүтээгдхүүний нэр" variant="outlined" />
            )} />
    </div >
}

export default ProductNameAuto