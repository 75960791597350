import React, { useState, useEffect } from 'react';
import CustTable from '../comp/custtable/custtable';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Stack from '@mui/material/Stack';
import * as XLSX from 'xlsx';
import { Button, TextField } from '@mui/material';
import { format } from 'date-fns';
import CompFilter from '../comp/compfilter';
import dayjs from 'dayjs';

const OrderDailyRep = ({ token }) => {

    const [data, setData] = useState([]);
    const [allcount, setAllCount] = useState([]);
    const [loading, setLoad] = useState(true);
    const [[start, end], setSEnd] = useState([0, 20]);
    const [startdate, setStartDate] = React.useState(dayjs());
    const [actstart, setActStart] = React.useState();
    const [comp, setComp] = useState("");

    const columns = [
        { field: 'prod_code', headerName: 'Баркод', width: 170  },
        { field: 'prod_name', headerName: 'Бүтээгдхүүний нэр', width: 300  },
        { field: 'prod_comp_id', headerName: 'Компани код', width: 100  },
        { field: 'com_name', headerName: 'Компани нэр', width: 120 },
        { field: 'prod_num_in_box', headerName: 'Хайрцаг дахь тоо', },
        { field: 'prod_price', headerName: 'Үнэ', },
        { field: 'aguulahUld', headerName: 'Агуулахын үлдэгдэл', width: 150 },
        { field: 'negtgel', headerName: 'Нэгтгэсэн тоо', width: 120  },
        { field: 'hairtsag', headerName: 'Хайрцаг тоо', },
        { field: 'uldegdel', headerName: 'Үлдэх тоо', },
    ];

    const downloadExcel = () => {
        var url = 'https://wms-cloud.com/api/OrderDailyReport?_start=0&_end=10000';
        if (actstart != undefined)
        {
            url = url+'&Ognoo='+actstart;
        }
        if (comp != "")
        {
            url = url+'&com_id='+comp;
        }
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        console.log(
            JSON.stringify(request)
        );
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    localStorage.removeItem('tok');
                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            setLoad(false);

            const workSheet = XLSX.utils.json_to_sheet(resp);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, "DailyReport");
            //Buffer
            let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
            //Binary string
            XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
            //Download
            XLSX.writeFile(workBook, "DailyOrderReport" + ".xlsx");

        });
    };

    useEffect(() => {
        var url = 'https://wms-cloud.com/api/OrderDailyReport?_start='+start+'&_end='+end;
        if (actstart != undefined)
        {
            url = url+'&Ognoo='+actstart;
        }
        if (comp != "")
        {
            url = url+'&com_id='+comp;
        }
        
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        console.log(
            JSON.stringify(request)
        );
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    localStorage.removeItem('tok');
                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            setData(resp);
            setLoad(false);
        });
    }, [start, end, startdate, comp]);

    const handleStartChange = (e) => {
        setLoad(true);
        var t = new Date(e);
        var d = format(t, 'yyyy-MM-dd');
        console.log("e: " + e + " : " + t + " : " + d);
        setStartDate(e);
        setActStart(d);
    }

    const handlePage = (e) => {
        setSEnd([e * 20, (e * 20) + 20]);
    }

    return <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className="parenthead">
            <div>
                <h3>Өдрийн нэгтгэл тайлан</h3>
            </div>
            <div style={{ display: "flex" }}>
            <div style={{ width: "150px", marginLeft: "3px", marginRight: "3px", height: "40px" }}>
                                {/* <TextField style={{ marginLeft: "5px" }} inputRef={searchRefName} size="small" label="Product name" onKeyDown={custSearchName} /> */}
                                <CompFilter token={token} handleComp={setComp} />
            </div> 
            <div style={{ width: "150px" , marginRight: "10px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Огноо"
                                inputFormat="YYYY-MM-DD"
                                value={startdate}
                                onChange={handleStartChange}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </div>
                <Button style={{ marginRight: "3px", height: "40px" }} onClick={downloadExcel} variant="outlined">Excel -ээр татах</Button>
            </div>

        </div>
        <div className="tablecont">
            <CustTable
                name={"Order Daily Report"}
                exportname={"order_daily_report"}
                columns={columns}
                items={data}
                allcont={allcount}
                handlePage={handlePage}
                loading={loading}
                cellname={"prod_name"}
                checkboxselect={false}
            // handlePage={}
            />
        </div>
    </div>

}
export default OrderDailyRep;