import { Button, TextField } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import CustTable from '../comp/custtable/custtable';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomerNameAuto from '../comp/custnameauto';
import Companydown from '../comp/companydown';
import CompFilter from '../comp/compfilter';
import BonusAdd from './bonusadd';
import BonusMod from './bonusmod';


const Bonus = ({token}) => {

    const [items, setItems] = useState([]);
    const [[start, end], setSEnd] = useState([0, 10])
    const [allcont, setAllCount] = useState(0);
    const [loading, setLoad] = useState(true);
    const [modal, setmodal] = useState(false);
    const [addmodal, setAddModal] = useState(false);
    const [custrow, setcustrow] = useState([]);
    const [compid, setCompany] = useState("");
    const [selected, setSelected] = useState({ cust_id: "Customer", cust_name: "Customer" });
    const [options, setOptions] = useState([]);

    const columns = [
        { field: 'id', headerName: 'Код' },
        { field: 'bo_comp_id', headerName: 'Компани код' },
        { field: 'bo_comp_name', headerName: 'Компани нэр', width: 150 },
        { field: 'bo_cust_id', headerName: 'Харилцагч код', width: 120 },
        { field: 'bo_cust_name', headerName: 'Харилцагч нэр', width: 350 },
        { field: 'bo_prod_desc', headerName: 'Тайлбар', width: 100 },
        { field: 'bo_bonus_prcnt', headerName: 'Хөнгөлөлтийн хувь', width: 150 },
        
    ];

    useEffect(() => {
        var url = 'https://wms-cloud.com/api/Bonus?_start='+start+'&_end='+end;
        if(compid!="")
        {
            url=url+'&bo_comp_id=' + compid;
        }
        if(selected.cust_id != "Customer")
        {
            url=url+'&bo_cust_id=' + selected.cust_id;
        }
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    localStorage.removeItem('tok');
                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            setItems(resp);
            setLoad(false);
        });

    }, [start, end, selected, compid])

    const rowClick = (e) => {

        setcustrow(e);
        setmodal(true);

    }

    const handlePage = (e) => {
        setSEnd([e * 20, (e * 20) + 20]);
    }

    const getdataforname = (e) => {
        var tok = localStorage.getItem("tok");
        var url = "";
        if (e == null) {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + selected.cust_name;

        } else {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + e.target.value;
        }
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });

        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    localStorage.removeItem('tok');
                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            var temp = [];
            resp.map((e) => {
                temp.push({ "cust_id": e.cust_id, "cust_name": e.cust_name });
            });
            setOptions(temp);
        });
    }

    const custRefresh = () => {
        window.location.reload(false);
    }


    return <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
    <div className="parenthead">
        <div>
            <h3>Урамшуулал</h3>
        </div>
        <div style={{display: "flex", width:"100%", justifyContent:"flex-end"}}>
                    
                <CompFilter style={{ marginRight: "3px",  height: "5px" , marginLeft: "5px"}} token={token} handleComp={setCompany} />
                <div style={{  width: "250px", marginLeft: "3px", marginRight: "10px" }}>
                    <CustomerNameAuto selected={selected} setProd={setSelected} options={options} getdataforbar={getdataforname}/>
                </div>
                <Button style={{ marginRight: "3px", height: "40px" , marginLeft: "3px", marginRight: "3px"}} variant="outlined" onClick={setAddModal} >Урамшуулал бүртгэх</Button>
        
        <Button variant="outlined" style={{ marginLeft: "3px", height: "40px" }} onClick={() => {
            window.location.reload(false);
        }}> <RefreshIcon /> </Button>
        </div>
    </div>
    <div className="tablecont">
        <CustTable
            name={"Bonus"}
            columns={columns}
            items={items}
            allcont={allcont}
            handlePage={handlePage}
            rowClick={rowClick}
            loading={loading} />
    </div>

    {modal ?
        <BonusMod handleClose={setmodal}
        token={token}
        open={modal}
        custrow={custrow}
        handleRefresh={custRefresh}
        handleModal={setmodal} /> : <></>
    }

    {addmodal ?
        <BonusAdd handleClose={setAddModal}
        token={token}
        open={addmodal}
        handleRefresh={custRefresh}
        handleModal={setAddModal} /> : <></>
    }
</div>

}



export default Bonus;
