import { Component } from 'react';
// import { Button } from 'react-admin';
import PropTypes from 'prop-types';
import streamSaver from 'streamsaver';
import { Button } from '@mui/material';

class PadaanButtonDavhar extends Component {
    handleClick = () => {
        const { record, } = this.props;

        console.log(record);
        const token = localStorage.getItem('tok');
        const headers = new Headers({ Accept: 'application/json' });
        headers.set('Authorization', `Bearer ${token}`);
        fetch(`https://wms-cloud.com/api/viewPadaanDavhar/${record.id}`, { method: 'GET', headers: headers, redirect: 'follow' })
            //fetch(`http://localhost:8080/viewPadaanDavhar/${record.id}`, { method: 'GET', headers: headers, redirect: 'follow'})
            .then((response) => {
                //response 200 baina uu shalgaad aldaa hevlene
                if (response.status < 200 || response.status >= 300) {
                    alert("Сервертэй холбогдоход алдаа гарлаа", { type: 'warning' });
                }
                else {
                    const fileStream = streamSaver.createWriteStream(`PadaanDetails${record.id}.xlsx`);
                    const writer = fileStream.getWriter();

                    const reader = response.body.getReader();

                    const pump = () => reader.read()
                        .then(({ value, done }) => {
                            if (done) writer.close();
                            else {
                                writer.write(value);
                                return writer.ready.then(pump);
                            }
                        });

                    pump()
                        .then(() => console.log('Closed the stream, Done writing'))
                        .catch(err => console.log(err));

                }

            })
            .catch((e) => {
                console.error(e);
                alert('Error: Aлдаа гарлаа', { type: 'warning' });
            });
    }

    render() {
        return <Button color="primary" onClick={this.handleClick}>Падаан хэвлэх(Анунгоо давхар урамшуулал)</Button>
    }
}

PadaanButtonDavhar.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showalertfication: PropTypes.func,
};

export default PadaanButtonDavhar;