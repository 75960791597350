import { Component } from 'react';
// import { Button } from 'react-admin';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";



class RevDeliveryButton extends Component {
    handleClick = () => {
        const { token, record } = this.props;

        console.log(record);
        const updatedRecord = JSON.stringify({ ord_id: record.id, ord_status: record.ord_status });
        // const token = localStorage.getItem('token');
        const headers = new Headers({ Accept: 'application/json' });
        headers.set('Authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
        fetch(`https://wms-cloud.com/api/OrderDeliveryReversal`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow' })
            //fetch(`http://localhost:8080/OrderDeliveryReversal`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow'})
            .then((response) => {
                //response 200 baina uu shalgaad aldaa hevlene
                if (response.status < 200 || response.status >= 300) {
                    alert("Сервертэй холбогдоход алдаа гарлаа", { type: 'warning' });
                    console.log(response.json);
                }
                
                return response.json();
            }).then(({ id, ord_status }) => {
                
                if (ord_status == 'Зарлага гаргасан') {
                    alert('Амжилттай', { type: 'success' });
                   
                }
                else {
                    if (ord_status == 'Статус буруу байна') {

                        alert('Статус буруу байна', { type: 'warning' });
                       
                    }
                    else {
                        alert(ord_status, { type: 'warning' });
                    }
                }
                // status shalgaad aldaa hevlene
                //status Зарлага гаргасан gesen baival amjilttai gej hevlene
            })
            .catch((e) => {
                console.error(e);
                alert('Error: алдаа гарлаа', { type: 'warning' });
            });
    }

    render() {
        return <Button color="primary" onClick={this.handleClick}>Хүргэлт буцаах</Button>
    }
}

RevDeliveryButton.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showNotification: PropTypes.func,
};

export default RevDeliveryButton;