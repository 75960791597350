import { Form, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from 'react';
import { Divider, FormControl, TextField, Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import '../css/customermod.css'


const CustAdd = ({ token, open, handleClose, handleRefresh }) => {

    const location = useLocation();
    const [loading, setLoad] = useState(false);
    const cid = useRef();
    const ccid = useRef();
    const cname = useRef();
    const cregnum = useRef();
    const cphone = useRef();
    const caddress = useRef();
    const cloc = useRef();
    const cloanamt = useRef();
    const ccretime = useRef();
    const cbonusprcnt = useRef();

    const KEY_EVENT_TYPE = 'keyup';


    const handleClick = () => {
        if (open) {
            handleClose(false);
        } else {
            handleClose(true);
        }
    }

    const handleEscape = (e) => {
        // console.log(e);
        if (e.keyCode == 27) {
            // alert("escape clicked");
            handleClose(false);
        }

    }

    useEffect(() => {
        // document.addEventListener(KEY_EVENT_TYPE, handleEscape, false);
        window.addEventListener(KEY_EVENT_TYPE, handleEscape);
        return () => {
            window.removeEventListener(KEY_EVENT_TYPE, handleEscape);
        }
    }, [handleEscape])

    const handleSubmit = () => {
        setLoad(true);
        var req = {
            "id": cid.current.value,
            "cust_id": ccid.current.value,
            "cust_name": cname.current.value,
            "cust_reg_num": cregnum.current.value,
            "cust_phone": cphone.current.value,
            "cust_address": caddress.current.value,
            "cust_location": cloc.current.value,
            "cust_loan_amt": parseFloat(cloanamt.current.value),
            "cust_cre_time": ccretime.current.value,
            "cust_bonus_prcnt": parseInt(cbonusprcnt.current.value)
        };

        console.log(req);

        // setLoad(false);

        const request = new Request('https://wms-cloud.com/api/Customers/', {
            method: 'POST',
            body: JSON.stringify(req),
            headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${token}` })
        });

        fetch(request).then((response) => {
            if (response.status == 200) {
                console.log("amjilttai " + JSON.stringify(response));
                alert("Амжилттай хадгалагдлаа. ");
                setLoad(false);
                handleClose(false);
                // customRefresh();
                handleRefresh();

            } else {
                console.log("Амжилтгүй: " + response.status);
                setLoad(false);
            }
        });

        console.log(JSON.stringify(req));
    }






    return <div className="custmodal">
        <div className="mcont">
            <div className="mheader">
                Харилцагч нэмэх
        <button onClick={() => handleClick()}>x</button>
            </div>
            <Divider />
            <div className="mccont">
                <div className="crow">
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField disabled={true} inputRef={cid} label="Код" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField disabled={true} inputRef={ccid} label="Харилцагчийн код" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={cname} label="Харилцагчийн нэр" fullWidth ></TextField>
                    </div>

                </div>
                <div className="crow">
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={cregnum} label="Харилцагчийн регистрийн дугаар" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={cphone} label="Харилцагчийн утасны дугаар" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={caddress} label="Харилцагчийн хаяг" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={cloc} label="Харилцагчийн байршил" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={cloanamt} label="Харилцагчийн зээлийн үлдэгдэл" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={ccretime} label="Үүссэн огноо" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={cbonusprcnt} label="Урамшуулал хувь" fullWidth ></TextField>
                    </div>
                </div>
                {loading ? <div className="custloads"> <CircularProgress /></div> : ""}
            </div>
            <div className="mfooter">
                <Button onClick={handleSubmit}>Хадгалах</Button>
                <Button onClick={handleClick}>Буцах</Button>
                {/* <button>Save</button> */}
                {/* <button>Cancel</button> */}
            </div>
        </div>

    </div>
}

export default CustAdd;