import React, { useState } from 'react';
import "../css/sidemenu.css"
import SideItem from './sideitem';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import MoneyOutlinedIcon from '@mui/icons-material/MoneyOutlined';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import ViewStreamOutlinedIcon from '@mui/icons-material/ViewStreamOutlined';
import { Divider } from '@mui/material';


const SideMenu = ({ icononly }) => {

    const [selected, setSelected] = useState(10);
    const role = useState();

    return <div className="smenu">
        <div className="smenuhead">
            WMS
        </div>
        <div className={icononly ? "smenucont" : "smenuconticon"}>
            <SideItem id={0} selected={selected} setSelected={setSelected} icononly={icononly} label="Хяналтын самбар" title="Dashboard" icons={<AssessmentOutlinedIcon />} />
            {localStorage.getItem('role').includes('101') ? <SideItem id={1} selected={selected} setSelected={setSelected} icononly={icononly} label="Компани" title="Companies" icons={<StoreOutlinedIcon />} /> : <></> }
            {localStorage.getItem('role').includes('102') ? <SideItem id={2} selected={selected} setSelected={setSelected} icononly={icononly} label="Харилцагч" title="Customers" icons={<PeopleAltOutlinedIcon />} /> : <></> }
            {localStorage.getItem('role').includes('103') ? <SideItem id={3} selected={selected} setSelected={setSelected} icononly={icononly} label="Брэнд" title="Brands" icons={<TextSnippetOutlinedIcon />} /> : <></> }
            {localStorage.getItem('role').includes('104') ? <SideItem id={4} selected={selected} setSelected={setSelected} icononly={icononly} label="Урамшуулал" title="Bonus" icons={<TextSnippetOutlinedIcon />} /> : <></> }
            {localStorage.getItem('role').includes('105') ? <SideItem id={5} selected={selected} setSelected={setSelected} icononly={icononly} label="Бүтээгдхүүн" title="Products" icons={<Inventory2OutlinedIcon />} /> : <></> }
            {localStorage.getItem('role').includes('106') ? <SideItem id={6} selected={selected} setSelected={setSelected} icononly={icononly} label="Захиалга" title="Orders" icons={<ViewStreamOutlinedIcon />} /> : <></> }
            {localStorage.getItem('role').includes('107') ? <SideItem id={7} selected={selected} setSelected={setSelected} icononly={icononly} label="Гүйлгээ" title="Transactions" icons={<ReceiptLongOutlinedIcon />} /> : <></> }
            {localStorage.getItem('role').includes('108') ? <SideItem id={8} selected={selected} setSelected={setSelected} icononly={icononly} label="Өдрийн нэгтгэл тайлан" title="OrderDailyRep" icons={<AssignmentOutlinedIcon />} /> : <></> }
            {localStorage.getItem('role').includes('109') ? <SideItem id={9} selected={selected} setSelected={setSelected} icononly={icononly} label="Захиалга нэгтгэл тайлан" title="OrderGrpRep" icons={<AssignmentOutlinedIcon />} /> : <></> }
            {localStorage.getItem('role').includes('110') ? <SideItem id={10} selected={selected} setSelected={setSelected} icononly={icononly} label="Захиалга тайлан" title="OrderRep" icons={<AssignmentOutlinedIcon />} /> : <></> }
            {localStorage.getItem('role').includes('111') ? <SideItem id={11} selected={selected} setSelected={setSelected} icononly={icononly} label="Захиалга тайлан бүгд" title="OrderRepAll" icons={<TextSnippetOutlinedIcon />} /> : <></> }
            {localStorage.getItem('role').includes('112') ? <SideItem id={12} selected={selected} setSelected={setSelected} icononly={icononly} label="Зээлийн түүх" title="LoanHistory" icons={<CreditCardOutlinedIcon />} /> : <></> }
            {localStorage.getItem('role').includes('113') ? <SideItem id={13} selected={selected} setSelected={setSelected} icononly={icononly} label="Зээлийн төлөлт" title="Loanpayments" icons={<CreditScoreOutlinedIcon />} /> : <></> }
        </div>


    </div>
}

export default SideMenu;