import { Button, CircularProgress, Divider,  TextField } from '@mui/material';
import { useRef, useEffect, useState } from 'react';
import BrandFilter from '../comp/brandfilter';
import Companydown from '../comp/companydown';
import '../css/orderAddDetail.css'

const OrderAddDetail = ({ handleClose, token, open, custrow, handleModal, handleRefresh, items, setitems, clrRef }) => {

    const [prodload, setProdload] = useState(false);
    const pcode = useRef();
    // const pcompid = useRef();
    const [pcompid, setComp] = useState("");
    const pname = useRef();
    const psize = useRef();
    const pnuminbox = useRef();
    const pprice = useRef();
    const puld = useRef();
    // const pav = useRef();
    const availableBal = useRef();
    const pbonusp = useRef();
    const too = useRef();

    // useEffect(() => {
    //     setBrand(custrow.prod_brand_id);
    // });

    useEffect(() => {
        pcode.current.value = custrow.prod_code;
        setComp(custrow.prod_comp_id);
        pname.current.value = custrow.prod_name;
        psize.current.value = custrow.prod_size+' '+custrow.prod_unit;
        pnuminbox.current.value = custrow.prod_num_in_box;
        pprice.current.value = custrow.prod_price;
        puld.current.value = custrow.prod_uldegdel;
        availableBal.current.value = custrow.available_bal;
        
        // pcre.current.value = custrow.prod_rcre_time;
        if(custrow.prod_bonus_prcnt!=0)
            {
                pbonusp.current.value = custrow.prod_bonus_unit+'-с дээш бараа авбал '+custrow.prod_bonus_prcnt +' хувь урамшуулал бодогдоно.' ;
            }
        else{
            pbonusp.current.value = 'байхгүй';
        }
        too.current.focus();
    }, [])

    const handleEscape = (e) => {
        // console.log(e);
        if (e.keyCode == 27) {
            // console.log("escape clicked");
            handleClose(false);
        }
        if (e.keyCode == 13) {
            // console.log("escape clicked");
            handleSubmit();
            handleClose(false);
        }

    }

    useEffect(() => {
        // document.addEventListener(KEY_EVENT_TYPE, handleEscape, false);
        window.addEventListener('keyup', handleEscape);
        return () => {
            window.removeEventListener('keyup', handleEscape);
        }
    }, [handleEscape])


    const handleClick = () => {
        console.log("open: " + open);
        if (open) {
            handleModal(false);
        }
    }

    const handleSubmit = () => {
        const toot=too.current.value;
        console.log("too "+ toot);
        if( toot !== "")
        {
            if(toot.includes("*"))
                {
                    let sum=1;
                    const array = toot.split("*");
                    for (let i = 0; i < array.length; i++) {
                        sum = sum * parseInt(array[i]);
                    }
                    too.current.value=sum;
                }
        setProdload(true);
        var temp = {
            'ordet_prod_code': pcode.current.value,
            'ordet_prod_name': pname.current.value,
            'ordet_price': pprice.current.value,
            'ordet_too': too.current.value
        }
        console.log(temp)
        const findValue = items.find(({ ordet_prod_code }) => ordet_prod_code === pcode.current.value);
        console.log("find value: "+findValue);
        if(findValue === undefined)
            {

                if(parseInt(too.current.value) > parseInt(availableBal.current.value))
                    {
                        alert("Боломжит үлдэгдэлээс давсан байна.");
                        setProdload(false);
                    }
                    else{
                        setitems(items =>[...items,temp] );
                        console.log("items "+JSON.stringify(items));
                        clrRef();
                        handleClose(false);
                        
                    }

            }
        else
        {
            alert("Аль хэдийн бүртгэгдсэн байна.");
            setProdload(false);
        }
    }
    else {
        alert("Тоо хоосон байна.");
        setProdload(false);
    }




    }
    return <div className="custmodal" >
        <div className="mcont">
            <div className="mheader">
                Барааны мэдээлэл оруулах
            <button onClick={() => handleClick()}>x</button>
            </div>
            <Divider />
            <div className="mccont">

                <div className="crow">
                    {/* <div style={{ width: "", paddingRight: "10px" }}>
                        <TextField inputRef={pcode} label="Product code" fullWidth ></TextField>
                    </div> */}
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                        }} inputRef={pcode} label="Баркод" fullWidth disabled></TextField>
                    </div>
                </div>
                {/* <Divider className="divide" /> */}
                <div className="crow">
                    <div style={{ width: "75%", paddingRight: "10px" }}>
                        <TextField sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                        }} inputRef={pname} label="Бүтээгдхүүний нэр" fullWidth disabled></TextField>
                    </div>
                    <div style={{ width: "25%", paddingRight: "10px" }}>
                        <TextField sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                        }} inputRef={psize } label="Хэмжээ нэгж" fullWidth disabled></TextField>
                    </div>
                </div>
                {/* <Divider className="divide" /> */}
                <div className="crow">

                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                        }} inputRef={pnuminbox} label="Хайрцаг дахь тоо" type="number" fullWidth disabled></TextField>
                    </div>
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                        }} inputRef={pprice} label="Үнэ" type="number" disabled fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                        }} inputRef={puld} label="Үлдэгдэл" type="number" disabled fullWidth ></TextField>
                    </div>
                    
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                        }} inputRef={availableBal} label="Боломжит үлдэгдэл" fullWidth disabled></TextField>
                    </div>

                </div>
                <div className="crow">

                    {/* <div style={{ width: "60%", paddingRight: "10px" }}>
                        <TextField inputRef={pcre} label="Publication " fullWidth ></TextField>
                    </div> */}
                    {}
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField sx={{
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "#000000",
                            },
                        }} inputRef={pbonusp} label="Урамшуулал" fullWidth disabled></TextField>
                    </div>
                </div>
                <div className="crow">
                <div style={{ width: "100%", paddingRight: "10px" }}>
                    <TextField inputRef={too} label="Тоо ширхэг" fullWidth color="primary" focused required ></TextField>
                </div>
                </div>

            </div>
            <div className="mfooter">
                <Button onClick={handleSubmit}>Save</Button>
                <Button onClick={handleClick}>Cancel</Button>
                {/* <button>Save</button> */}
                {/* <button>Cancel</button> */}
            </div>
            {prodload ?
                <div className="prodload">
                    <CircularProgress />
                </div> : ""}
        </div>
    </div>
}

export default OrderAddDetail;