import ReactDataGrid, { textEditor } from 'react-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import '../../css/custtable.css'
import Pagination from '../pagination';
// import CustDDown from '../company'
import { Button, TextField, FormControl, InputLabel, Select, MenuItem, Popper, Fade, Paper } from '@mui/material';
import { DataGrid, GridColumns, GridColDef, GridApi, GridRowsProp } from '@mui/x-data-grid';
import { useRef, useEffect, useState } from 'react';
// import { JsonToExcel } from "react-json-to-excel";
import * as XLSX from 'xlsx';


const CustTable = ({
    token,
    name,
    handleSearch,
    exportname,
    columns,
    items,
    allcont,
    rowClick,
    handlePage,
    createshow,
    newmod,
    celledit,
    handleCreate,
    rowid,
    showsearch,
    loading,
    cellname,
    checkboxselect,
    subrow,
    setSubrow,
    setselect }) => {


    console.log("loading: " + loading);
    const [itemrows, setRows] = useState(items);
    const [filt, setFilter] = useState();
    // const [gridload, setGridload] = useState(false);
    const searchRef = useRef();

    // const [open, setOpen] = useState();

    const popperRef = useRef();
    const [anchorEl, setAnchorEl] = useState(null);
    const [value, setValue] = useState("");

    const open = Boolean(anchorEl);

    const handleRow = (e) => {
        console.log(JSON.stringify(e.row));
        rowClick(e.row);
    }




    const openCreate = () => {
        if (newmod) {
            handleCreate(false);
        } else {
            handleCreate(true);
        }
    }

    const custSearch = (e) => {
        if (e.keyCode == 13) {
            handleSearch(searchRef.current.value);
        }
    }


    function generateRandom() {
        var length = 8,
            charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
            retVal = "";
        for (var i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }


    const downloadExcel = () => {
        const workSheet = XLSX.utils.json_to_sheet(items);
        const workBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workBook, workSheet, "students");
        //Buffer
        let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
        //Binary string
        XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
        //Download
        XLSX.writeFile(workBook, exportname + ".xlsx");
    };

    // const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    //     console.log(fromRow);
    // }

    const handlePopperOpen = (event) => {
        const id = event.currentTarget.dataset.id;
        const row = items.find((r) => r.id === id);
        // console.log(event.target.dataset.field);
        if (event.target.dataset.field == cellname) {

            setValue(event.target.innerText);
            setAnchorEl(event.currentTarget);
        }
    };

    const handlePopperClose = (event) => {
        if (
            anchorEl == null ||
            popperRef.current.contains(event.nativeEvent.relatedTarget)
        ) {
            return;
        }

        setAnchorEl(null);
    };


    const handleRowSelect = (e) => {
        var temp = [];
        const selectteddata = items.map((item) => {
            e.map((i) => {

                    if (i.includes(item.prod_code)||i=="ord"+item.id || i=="tran"+item.id) {
                         //console.log(item.prod_code);
                        console.log(item.id);
                        temp.push(item);
                    }

            });
        }
        );
        if (temp.length > 0) {
            setSubrow(true);
        } else {
            setSubrow(false);
        }


        //console.log("temp: " + temp);
        setselect(temp);
    }

    return <div className="custtablecont">
        {showsearch ? <div className="custtableheader">
            <div >
                {name}
            </div>

            <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                <div>
                    {exportname === "" || exportname === undefined ?
                        "" :
                        <Button style={{ marginRight: "3px", height: "40px" }} onClick={downloadExcel} variant="outlined">Export</Button>
                    }

                </div>
                {createshow ? <div>
                    <Button style={{ marginRight: "3px", height: "40px" }} variant="outlined" onClick={openCreate} >Create</Button>
                </div> : ""}

                <div>
                    <TextField style={{ marginLeft: "5px" }} inputRef={searchRef} size="small" label="Name" onKeyDown={custSearch} />
                </div>
            </div>
        </div> : <></>}


        {checkboxselect ?
            <DataGrid
                getRowId={rowid}
                onCellEditCommit={(e) => console.log(e.row_id)}
                columns={columns}
                rows={items}
                // onRowClick={celledit ? () => "" : (e) => handleRow(e)}
                onRowDoubleClick={celledit ? () => "" : (e) => handleRow(e)}
                loading={loading}
                componentsProps={{
                    cell: {
                        onMouseEnter: handlePopperOpen
                    }
                }}
                checkboxSelection
                onSelectionModelChange={(e) => handleRowSelect(e)}
            /> : <DataGrid
                getRowId={rowid}
                onCellEditCommit={(e) => console.log(e.row_id)}
                columns={columns}
                rows={items}
                // onRowClick={celledit ? () => "" : (e) => handleRow(e)}
                onRowDoubleClick={celledit ? () => "" : (e) => handleRow(e)}
                loading={loading}
                componentsProps={{
                    cell: {
                        onMouseEnter: handlePopperOpen
                    }
                }}
            />}
        <Popper
            ref={popperRef}
            open={open}
            anchorEl={anchorEl}
            placement={"right"}
            transition
            onMouseLeave={() => setAnchorEl(null)}
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={10}>
                    <Paper sx={{ transform: "translateX(0px)" }}>
                        <div style={{ padding: "15px" }}>
                            {value}
                        </div>
                    </Paper>
                </Fade>
            )}
        </Popper>
        <div className="custtablefooter">
            <Pagination
                totalItems={allcont}
                perpage={20}
                range={10}
                handlePage={handlePage} />
        </div>

    </div>
    // }


}

export default CustTable;