import React, { useState, useEffect, useRef } from 'react';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import { Button, Divider, FormControl, InputLabel, MenuItem, Modal, Select, Table, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustModal from './productmodal';
import CustTable from '../comp/custtable/custtable';
import ProductModal from './productmodal';
import ProductAdd from './productadd';
import '../css/product.css';
import Brand from '../comp/brand';
import RefreshIcon from '@mui/icons-material/Refresh';
import Companydown from '../comp/companydown';
import CompFilter from '../comp/compfilter';
import * as XLSX from 'xlsx';
import Menu from '@mui/material/Menu';
import ListIcon from '@mui/icons-material/List';


const Products
    = ({ token }) => {
        const [items, setItems] = useState([]);
        const [exportitems, setexportItems] = useState([]);
        const [modal, setmodal] = useState(false);
        const [custrow, setcustrow] = useState([]);
        const [loading, setLoading] = useState(true);
        const [allcont, setAllCount] = useState(0);
        const [start, setStart] = useState(0);
        const [end, setEnd] = useState(20);
        const [sbarcode, setSBarcode] = useState("");
        const [sname, setSname] = useState("");
        const [sbrand, setSbrand] = useState("");
        const [sbalance, setSbalance] = useState("");
        const [addmodal, setAddModal] = useState(false);
        const [comp, setComp] = useState("");
        const [menu, setMenu] = useState(false);
        const navigate = useNavigate();

        const searchRef = useRef();
        const searchRefBarcode = useRef();
        const searchRefName = useRef();
        const searchRefBalance = useRef();
        const searchRefBrand = useRef();

        const [mcode, setMcode] = useState(false);
        const [mname, setMname] = useState(true);
        const [mbal, setMBalance] = useState(false);
        const [mcomp, setmCompany] = useState(false);
        const [mbrand, setMBrand] = useState(false);


        const columns = [

            { field: 'prod_code', headerName: 'Баркод', width: 130 },
            { field: 'prod_comp_id', headerName: 'Компани код', width: 120 },
            { field: 'prod_name', headerName: 'Бүтээгдхүүний нэр', minWidth: 300, maxWidth: 500 },
            { field: 'prod_size', headerName: 'Хэмжээ', width: 100 },
            { field: 'prod_desc', headerName: 'Тайлбар', width: 100 },
            { field: 'prod_num_in_box', headerName: 'Хайрцаг тоо', width: 100 },
            { field: 'prod_price', headerName: 'Үнэ', width: 70 },
            { field: 'prod_uldegdel', headerName: 'Үлдэгдэл', width: 70 },
            { field: 'prod_brand_id', headerName: 'Брэнд код', width: 100 },
            { field: 'prod_tasarsan_eseh', headerName: 'Тасарсан эсэх', width: 70 },
            { field: 'prod_rcre_time', headerName: 'Үүсгэсэн огноо', width: 100 },
            { field: 'prod_unit', headerName: 'Хэмжих нэгж', width: 70 },
            { field: 'prod_bonus_prcnt', headerName: 'Урам/хувь', width: 100 },
            { field: 'prod_bonus_unit', headerName: 'Урам/тоо', width: 70 }
        ];

        useEffect(() => {
            var url = 'https://wms-cloud.com/api/Products?_start=' + start
                + '&_end=' + end;
            if (sname != "") {
                url = url + "&prod_name=" + sname;
            }
            if (sbrand != "") {
                url = url + "&brand_id=" + sbrand;
            }
            if (sbarcode != "") {

                url = url + "&q=" + sbarcode
            }
            if (comp != "") {
                url = url + "&com_id=" + comp;
            }
            if (sbalance != "") {
                url = url + "&uldegdel=" + sbalance;
            }


            console.log('url" ' + url);
            const request = new Request(url
                , {
                    method: 'GET',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        
                        'Access-Control-Allow-Headers': 'X-Total-Count'
                    })
                });
            fetch(request).then(
                (response) => {
                    if (response.status == 200) {
                        console.log('status 200');
                        // console.log(response.headers.get('X-Total-Count'));
                        setAllCount(response.headers.get('X-Total-Count'));
                        return response.json();
                    } else if (response.state == 500) {
                        alert("Алдаа гарлаа дахин оролдоно уу.");
                    } else {
                        // localStorage.getItem('tok')
                        localStorage.removeItem('tok');

                        window.location.reload(false);
                        alert("Алдаа гарлаа.")
                    }
                }
            ).then((resp) => {
                // console.log(JSON.stringify(resp));
                // changeLoad();
                setLoading(false);
                setItems(resp);

            });

        }, [start, end, sbarcode, sname, loading, sbrand, comp, sbalance]);

        const downloadExcel = () => {
            var url = 'https://wms-cloud.com/api/Products?_start=0&_end=10000'
            if (sname != "") {
                url = url + "&prod_name=" + sname;
            }
            if (sbrand != "") {
                url = url + "&brand_id=" + sbrand;
            }
            if (sbarcode != "") {

                url = url + "&q=" + sbarcode
            }
            if (comp != "") {
                url = url + "&com_id=" + comp;
            }
            if (sbalance != "") {
                url = url + "&uldegdel=" + sbalance;
            }
            const request = new Request(url
                , {
                    method: 'GET',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        
                        'Access-Control-Allow-Headers': 'X-Total-Count'
                    })
                });
            fetch(request).then(
                (response) => {
                    if (response.status == 200) {
                        console.log('status 200');
                        // console.log(response.headers.get('X-Total-Count'));
                        setAllCount(response.headers.get('X-Total-Count'));
                        return response.json();
                    } else if (response.state == 500) {
                        alert("Алдаа гарлаа дахин оролдоно уу.");
                    } else {
                        // localStorage.getItem('tok')
                        localStorage.removeItem('tok');

                        window.location.reload(false);
                        alert("Алдаа гарлаа.")
                    }
                }
            ).then((resp) => {
                // console.log(JSON.stringify(resp));
                // changeLoad();
                setLoading(false);
                setexportItems(resp);

                const workSheet = XLSX.utils.json_to_sheet(resp);
                const workBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workBook, workSheet, "students");
                //Buffer
                let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
                //Binary string
                XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
                //Download
                XLSX.writeFile(workBook, "Products" + ".xlsx");

            });

        };

        const changeLoad = () => {
            if (loading) {
                setLoading(false);
            } else {
                setLoading(true);
            }
        }



        const rowClick = (e) => {
            // console.log("row: " + JSON.stringify(e));
            setcustrow(e);
            // console.log("custrow: " + custrow);
            setmodal(true);
            // navigate('/ProductMod', { state: { item: e } });

        }

        const handlePage = (p) => {
            console.log(p);
            console.log("darson from outside: " + p);
            // setSend([, (p * 15 + 15)]);
            // addItem((p * 15), (e * 15 + 15));
            setStart(p * 20);
            setEnd((p * 20) + 20);
        }


        const handleSearch = (e) => {
            // setSstr(e);
        }

        const custSearchBar = (e) => {
            if (e.keyCode == 13) {
                changeLoad();
                console.log(searchRefBarcode.current.value);
                setSBarcode(searchRefBarcode.current.value);
                setStart(0);
                setEnd(20);
                // searchRefBarcode.current.value = null;
            }
        }

        const custSearchBrand = () => {
            // setSbrand();
            console.log();
        }

        const custSearchName = (e) => {
            if (e.keyCode == 13) {
                changeLoad();
                console.log("from enter: " + searchRefName.current.value);
                setSname(searchRefName.current.value);
                setStart(0);
                setEnd(20);
                // searchRefName.current.value = null
            }

            // console.log("from prod: " + e);
        }

        const fullRefresh = () => {
            window.location.reload(false);
        }

        const custRefresh = () => {

            var url = 'https://wms-cloud.com/api/Products?_start=' + start
            + '&_end=' + end;
        if (sname != "") {
            url = url + "&prod_name=" + sname;
        }
        if (sbrand != "") {
            url = url + "&brand_id=" + sbrand;
        }
        if (sbarcode != "") {

            url = url + "&q=" + sbarcode
        }
        if (comp != "") {
            url = url + "&com_id=" + comp;
        }
        if (sbalance != "") {
            url = url + "&uldegdel=" + sbalance;
        }


        console.log('url" ' + url);
        const request = new Request(url
            , {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    
                    'Access-Control-Allow-Headers': 'X-Total-Count'
                })
            });
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    console.log('status 200');
                    // console.log(response.headers.get('X-Total-Count'));
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.state == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            // console.log(JSON.stringify(resp));
            // changeLoad();
            setLoading(false);
            setItems(resp);

        });
        }

        const openCreate = () => {

        }

        const handleChange = (e) => {
            setLoading(true);
            setSbalance(e.target.value);
        }

        const customRefresh = () => {
            window.location.reload(false);
        }

        const [anchorEl, setAnchorEl] = React.useState(null);
        const open = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = (e) => {
            console.log("menu: " + e);
            if (e == 'mcode') {
                if (mcode) {
                    setMcode(false);
                } else {
                    setMcode(true);
                }
            } else if (e == 'mname') {
                if (mname) {
                    setMname(false);
                } else {
                    setMname(true);
                }
            } else if (e == 'mbal') {
                if (mbal) {
                    setMBalance(false);
                } else {
                    setMBalance(true);
                }
            } else if (e == 'mcomp') {
                if (mcomp) {
                    setmCompany(false);
                } else {
                    setmCompany(true);
                }
            } else if (e == 'mbrand') {
                if (mbrand) {
                    setMBrand(false);
                } else {
                    setMBrand(true);
                }
            }

            setAnchorEl(null);
        }


        return <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <div className="parenthead">
                <h3>Бүтээгдхүүн</h3>

                <div style={{ flex: "1", display: "flex", justifyContent: "flex-end", flexDirection: "" }}>
                    <div style={{ display: "flex" }}>
                        <Button style={{ marginRight: "3px", height: "40px" }} variant="outlined" onClick={setAddModal} >Бүтээгдхүүн үүсгэх</Button>
                        { <div style={{ width: "150px", }}>
                            <TextField style={{}} inputRef={searchRefBarcode} size="small" label="Баркодоор хайх" onKeyDown={custSearchBar} />
                        </div> }
                        <div style={{ width: "5px" }}></div>
                        {mname ? <div style={{ width: "180px" }}>
                            <TextField inputRef={searchRefName} size="small" label="Бүтээгдхүүний нэр" onKeyDown={custSearchName} />
                        </div> : <></>}
                        {mbal ?
                            <div style={{ width: "150px" }}>
                                <FormControl fullWidth size="small" >
                                    <InputLabel id="demo-simple-select-label">Үлдэгдэл</InputLabel>
                                    <Select style={{ marginLeft: "5px" }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={sbalance}
                                        label="Үлдэгдэлтэй эсэх"
                                        onChange={handleChange}
                                    >
                                        {/* {items.map(e => <MenuItem key={e.id} value={e.id}>{e.brand_name}</MenuItem>)} */}
                                        <MenuItem key={"YES"} value={"YES"}>{"Үлдэгдэлтэй"}</MenuItem>
                                        <MenuItem key={"NO"} value={"NO"}>{"Үлдэгдэлгүй"}</MenuItem>
                                        <MenuItem key={""} value={""}>{"Бүгд"}</MenuItem>
                                    </Select>
                                </FormControl>
                            </div> : <></>}
                        {mcomp ?
                            <div style={{ width: "150px", marginLeft: "3px", marginRight: "3px", height: "40px" }}>
                                {/* <TextField style={{ marginLeft: "5px" }} inputRef={searchRefName} size="small" label="Product name" onKeyDown={custSearchName} /> */}
                                <CompFilter token={token} handleComp={setComp} />
                            </div> : <></>}
                    </div>
                    {mbrand ?
                        <div style={{}}>
                            <Brand token={token} brand={sbrand} handleBrand={setSbrand} />
                        </div> : <></>}
                    <Button style={{ marginLeft: "5px", height: "40px" }} variant="outlined" onClick={() => {
                        // setSbrand("");
                        // setSname("");
                        // setSBarcode("");
                        fullRefresh();
                    }}> <RefreshIcon /> </Button>
                    <div style={{ display: "flex" }}>
                        <Button style={{ marginLeft: "5px", height: "40px" }} onClick={downloadExcel} variant="outlined">Excel-ээр авах</Button>
                    </div>
                    <div>
                        <Button
                            style={{ marginLeft: "5px", height: "40px" }}
                            id="demo-positioned-button"
                            aria-controls={open ? 'demo-positioned-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            variant="outlined"
                            onClick={handleClick}
                        >
                            <ListIcon />
                        </Button>
                        <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <MenuItem onClick={() => handleClose('mcode')}>Баркодоор хайх</MenuItem>
                            <MenuItem onClick={() => handleClose('mname')}>Нэрээр хайх</MenuItem>
                            <MenuItem onClick={() => handleClose('mbal')}>Үлдэгдэлтэй эсэх</MenuItem>
                            <MenuItem onClick={() => handleClose('mcomp')}>Компаниар хайх</MenuItem>
                            <MenuItem onClick={() => handleClose('mbrand')}>Брэндээр хайх</MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
            <div className="tablecont">
                <CustTable name={"Products"}
                    loading={loading}
                    handleSearch={handleSearch}
                    columns={columns}
                    items={items}
                    allcont={allcont}
                    rowClick={rowClick}
                    handlePage={handlePage}
                    celledit={false}
                    rowid={(row) => 'prod' + row.prod_code}
                    createshow={true}
                    newmod={addmodal}
                    handleCreate={setAddModal}
                    showsearch={false}
                    cellname={"prod_name"}
                />
            </div>

            {modal ?
                <ProductModal handleClose={setmodal}
                    token={token}
                    open={modal}
                    custrow={custrow}
                    handleRefresh={custRefresh}
                    handleModal={setmodal} /> : <></>
            }
            {addmodal ?
                <ProductAdd
                    handleClose={setAddModal}
                    token={token}
                    open={addmodal}
                    handleRefresh={custRefresh}
                /> : <></>
            }
        </div>
    }

export default Products;