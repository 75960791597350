import { useState } from 'react';
// import { Button } from 'react-admin';
import PropTypes from 'prop-types';
import { Button , Dialog, DialogContent, DialogTitle,} from '@mui/material';
import { useNavigate } from "react-router-dom";



function TuluhHelberButton({ record, noti }) {

    const [showDialog, setShowDialog] = useState(false);
    const navigate = useNavigate();
    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const token = localStorage.getItem('tok');

    const handlebelenSubmit = () => {

        console.log(record);
        const updatedRecord = JSON.stringify({ ord_id: record.id, ord_tuluh_helber: 'Бэлэн' });
        // const token = localStorage.getItem('token');
        const headers = new Headers({ Accept: 'application/json' });
        headers.set('Authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
        fetch(`https://wms-cloud.com/api/OrderChangeTuluhHelber`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow' })
            //fetch(`http://localhost:8080/OrderChangeTuluhHelber`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow'})
            .then((response) => {
                //response 200 baina uu shalgaad aldaa hevlene
                if (response.status < 200 || response.status >= 300) {
                    alert("Сервертэй холбогдоход алдаа гарлаа", { type: 'warning' });
                    console.log(response.json);
                }
                
                return response.json();
            }).then(({ id, status }) => {
                
                if (status == 'Амжилттай') {
                    alert('Амжилттай', { type: 'success' });
                    navigate('/Orders');
                }
                else {
                    if (status == 'Статус буруу байна') {

                        alert('Статус буруу байна', { type: 'warning' });
                       
                    }
                    else {
                        alert(status, { type: 'warning' });
                    }
                }
                // status shalgaad aldaa hevlene
                //status Зарлага гаргасан gesen baival amjilttai gej hevlene
            })
            .catch((e) => {
                console.error(e);
                alert('Error: алдаа гарлаа', { type: 'warning' });
            });
    }

    const handlebelenbusSubmit = () => {

        console.log(record);
        const updatedRecord = JSON.stringify({ ord_id: record.id, ord_tuluh_helber: 'Бэлэн бус' });
        // const token = localStorage.getItem('token');
        const headers = new Headers({ Accept: 'application/json' });
        headers.set('Authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
        fetch(`https://wms-cloud.com/api/OrderChangeTuluhHelber`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow' })
            //fetch(`http://localhost:8080/OrderChangeTuluhHelber`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow'})
            .then((response) => {
                //response 200 baina uu shalgaad aldaa hevlene
                if (response.status < 200 || response.status >= 300) {
                    alert("Сервертэй холбогдоход алдаа гарлаа", { type: 'warning' });
                    console.log(response.json);
                }
                
                return response.json();
            }).then(({ id, status }) => {
                
                if (status == 'Амжилттай') {
                    alert('Амжилттай', { type: 'success' });
                    navigate('/Orders');
                }
                else {
                    if (status == 'Статус буруу байна') {

                        alert('Статус буруу байна', { type: 'warning' });
                       
                    }
                    else {
                        alert(status, { type: 'warning' });
                    }
                }
                // status shalgaad aldaa hevlene
                //status Зарлага гаргасан gesen baival amjilttai gej hevlene
            })
            .catch((e) => {
                console.error(e);
                alert('Error: алдаа гарлаа', { type: 'warning' });
            });
    }

    return (
        <>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Гүйлгээ буцаах">
                <DialogTitle>Төлөх хэлбэр</DialogTitle>
                <DialogContent>
                    <div >
                    <Button color="primary" onClick= {handlebelenSubmit} >Бэлэн</Button>
                    <Button color="primary" onClick= {handlebelenbusSubmit}>Бэлэн бус</Button>
                    <br/>
                    <TulburtuluhButtonToolbar onCancel={handleCloseClick} />
                    </div>
                </DialogContent>
            </Dialog>
            <Button color="primary" onClick={handleClick}>Төлөх хэлбэр (Бэлэн)</Button>
            {}
        </>
    );
}

function TulburtuluhButtonToolbar({ onCancel, ...props }) {
    return (
        <div>
            <Button onClick={onCancel}>Буцах</Button>
        </div>

    );
}


export default TuluhHelberButton;