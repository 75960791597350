import { Form, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from 'react';
import { Divider, FormControl, TextField, Button, Select, MenuItem } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

import '../css/customermod.css'
import { format } from 'date-fns';
import { idText } from "typescript";



const CompanyMod = ({ token, handleClose, custrow, handleRefresh }) => {
    const location = useLocation();
    const [loading, setLoad] = useState(false);
    const [helber, setHelber] = useState("");
    const id = useRef();
    const com_id = useRef();
    const com_name = useRef();
    const com_reg_num = useRef();
    const com_account = useRef();
    const com_phone = useRef();
    const com_bonus_prcnt = useRef();
    const com_bonus_amt = useRef();

    useEffect(() => {
        console.log(JSON.stringify(custrow));
        id.current.value = custrow.id;
        com_id.current.value = custrow.com_id;
        com_name.current.value = custrow.com_name;
        com_reg_num.current.value = custrow.com_reg_num;
        com_account.current.value = custrow.com_account;
        com_phone.current.value = custrow.com_phone;
        com_bonus_prcnt.current.value = custrow.com_bonus_prcnt;
        com_bonus_amt.current.value = custrow.com_bonus_amt;
        setHelber(custrow.com_bonus_helber);
    }, []);


    const handleEscape = (e) => {
        console.log(e);
        if (e.keyCode == 27) {
            // alert("escape clicked");
            handleClose(false);
        }

    }

    const handleChange = (e) => {
        setHelber(e.target.value);
    }

    useEffect(() => {
        // document.addEventListener(KEY_EVENT_TYPE, handleEscape, false);
        window.addEventListener('keyup', handleEscape);
        return () => {
            window.removeEventListener('keyup', handleEscape);
        }
    }, [handleEscape])

    const handleClick = () => {
        handleClose(false);
    }

    const handleSubmit = () => {
        setLoad(true);
        var req = {
            "id": id.current.value,
            "com_id": com_id.current.value,
            "com_name": com_name.current.value,
            "com_reg_num": com_reg_num.current.value,
            "com_phone": com_account.current.value,
            "com_account": com_account.current.value,
            "com_bonus_prcnt": com_bonus_prcnt.current.value,
            "com_bonus_amt": com_bonus_amt.current.value,
            "com_bonus_helber": helber,
        };

        const request = new Request('https://wms-cloud.com/api/Company/' + id.current.value, {
        //const request = new Request('http://localhost:8080/Company/' + id.current.value, {
            method: 'PUT',
            body: JSON.stringify(req),
            headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${token}` })
        });

        fetch(request).then((response) => {
            if (response.status == 200) {
                console.log("amjilttai " + JSON.stringify(response));
                alert("Амжилттай хадгалагдлаа");
                setLoad(false);
                handleClose(false);
                handleRefresh();

            } else {
                console.log("Амжилтгүй: " + response.status);
                setLoad(false);
            }
        });

        console.log(JSON.stringify(req));
    }

    const handleDateChange = (e) => {
        var t = new Date(e);
        var d = format(t, 'yyyy-MM-dd');
        console.log("e: " + e + " : " + t + " : " + d);
    }


    return <div className="custmodal">
        <div className="mcont">
            <div className="mheader">
                Компани мэдээлэл засах
            <button onClick={() => handleClick()}>x</button>
            </div>
            <Divider />
            <div className="mccont">
                <div className="crow">
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField disabled={true} inputRef={id} label="Код" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField disabled={true} inputRef={com_id} label="Компани код" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={com_name} label="Компани нэр" fullWidth ></TextField>
                    </div>

                </div>
                <div className="crow">
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={com_reg_num} label="Компани регистр" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={com_phone} label="Компани утасны дугаар" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={com_account} label="Компани данс" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={com_bonus_prcnt} label="Компани хөнгөлөлт хувь" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={com_bonus_amt} label="Хөнгөлөлт эдлэх дүн (дээш байвал)" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <Select style={{ marginLeft: "5px" }}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={helber}
                                        label="Хөнгөлөлт эдлэх хэлбэр(Зөвхөн бэлэн)"
                                        onChange={handleChange}
                                    >
                                        <MenuItem key={"Бэлэн"} value={"Бэлэн"}>{"Бэлэн"}</MenuItem>
                                        <MenuItem key={""} value={""}>{"Бүгд"}</MenuItem>
                                    </Select>
                    </div>
                </div>
                {loading ? <div className="custloads"> <CircularProgress /></div> : ""}
            </div>
            <div className="mfooter">
                <Button onClick={handleSubmit}>Save</Button>
                <Button onClick={handleClick}>Cancel</Button>
                {/* <button>Save</button> */}
                {/* <button>Cancel</button> */}
            </div>
        </div>

    </div>
}

export default CompanyMod;

