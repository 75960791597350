import React, { useState, useRef } from 'react';
import {useNavigate } from "react-router-dom";
import { Button, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';

function OrderRevButton({ record, noti }) {
    const [showDialog, setShowDialog] = useState(false);
    const tailbar = useRef();
    const navigate = useNavigate();
    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };
    const handleSubmit = () => {

        setShowDialog(false);
        const updatedRecord = JSON.stringify({ id: record.id, ord_id: record.id, ord_status: record.ord_status, ord_emp_id: localStorage.getItem('uname'), ord_tuluh_helber: tailbar.current.value });
        const token = localStorage.getItem('tok');
        const headers = new Headers({ Accept: 'application/json' });
        headers.set('Authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
        fetch(`https://wms-cloud.com/api/OrderReversal`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow' })
            //fetch(`http://localhost:8080/OrderReversal`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow'})
            .then((response) => {
                //response 200 baina uu shalgaad aldaa hevlene
                if (response.status < 200 || response.status >= 300) {
                    alert("Сервертэй холбогдоход алдаа гарлаа", { type: 'warning' });
                }
                return response.json();
            }).then(({ ord_status }) => {
                if (ord_status == 'Захиалга авсан') {
                    alert("Гүйлгээ амжилттай буцсан", { type: 'success' });
                    navigate('/Orders');
                }
                else {

                    alert(ord_status, { type: 'warning' });
                }
            })
            .catch((e) => {
                console.error(e);
                alert('Error: Гүйлгээ хийхэд алдаа гарлаа', { type: 'warning' });
            });
    }

    return (
        <>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Гүйлгээ буцаах">
                <DialogTitle>Гүйлгээ буцаах</DialogTitle>
                <DialogContent>
                    <div onSubmit={handleSubmit} >
                    <TextField label="Тайбар бичих" inputRef={tailbar} source="tailbar" />
                    <br/>
                    <OrderRevButtonToolbar onCancel={handleCloseClick} onclk={handleSubmit}/>
                    </div>
                </DialogContent>
            </Dialog>
            <Button color="primary" onClick={handleClick}>Гүйлгээ буцаах</Button>
            {}
        </>
    );
}

function OrderRevButtonToolbar({ onCancel,onclk, ...props }) {
    return (
        <div>
            <Button submitOnEnter={true} onClick={onclk}> Хадгалах </Button>
            <Button onClick={onCancel}>Буцах</Button>
        </div>

    );
}


export default OrderRevButton;