import React, { useState, useRef } from 'react';
import {useNavigate } from "react-router-dom";
import { Button, Dialog, DialogContent, DialogTitle, TextField } from '@mui/material';

function TulburtuluhButton({ record, noti }) {
    const [showDialog, setShowDialog] = useState(false);
    const [isdisabled, setisdisabled] = useState(false);
    const navigate = useNavigate();
    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };
    const handlezeelSubmit = () => {

        setisdisabled(true);
        setShowDialog(false);
        const updatedRecord = JSON.stringify({ ord_id: record.id, ord_status: record.ord_status, ord_tuluh_helber: 'Зээл' });
        const token = localStorage.getItem('tok');
        const headers = new Headers({ Accept: 'application/json' });
        headers.set('Authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
        fetch(`https://wms-cloud.com/api/OrderPayment`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow' })
            //fetch(`http://localhost:8080/OrderPayment`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow'})
            .then((response) => {
                //response 200 baina uu shalgaad aldaa hevlene
                if (response.status < 200 || response.status >= 300) {
                    alert("Сервертэй холбогдоход алдаа гарлаа", { type: 'warning' });
                }
                return response.json();
            }).then(({ status }) => {
                if (status == 'Амжилттай') {
                    alert("Амжилттай", { type: 'success' });
                    navigate('/Orders');
                    setisdisabled(false);
                }
                else {

                    alert(status, { type: 'warning' });
                    setisdisabled(false);
                }
            })
            .catch((e) => {
                console.error(e);
                alert('Error: алдаа гарлаа', { type: 'warning' });
                setisdisabled(false);
            });
    }
    const handlebelenSubmit = () => {

        setShowDialog(false);
        const updatedRecord = JSON.stringify({ ord_id: record.id, ord_status: record.ord_status, ord_tuluh_helber: 'Бэлэн' });
        const token = localStorage.getItem('tok');
        const headers = new Headers({ Accept: 'application/json' });
        headers.set('Authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
        fetch(`https://wms-cloud.com/api/OrderPayment`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow' })
            //fetch(`http://localhost:8080/OrderPayment`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow'})
            .then((response) => {
                //response 200 baina uu shalgaad aldaa hevlene
                if (response.status < 200 || response.status >= 300) {
                    alert("Сервертэй холбогдоход алдаа гарлаа", { type: 'warning' });
                }
                return response.json();
            }).then(({ status }) => {
                if (status == 'Амжилттай') {
                    alert("Амжилттай", { type: 'success' });
                    navigate('/Orders');
                }
                else {

                    alert(status, { type: 'warning' });
                }
            })
            .catch((e) => {
                console.error(e);
                alert('Error:  алдаа гарлаа', { type: 'warning' });
            });
    }
    const handlebankSubmit = () => {

        setShowDialog(false);
        const updatedRecord = JSON.stringify({ ord_id: record.id, ord_status: record.ord_status, ord_tuluh_helber: 'Банк' });
        const token = localStorage.getItem('tok');
        const headers = new Headers({ Accept: 'application/json' });
        headers.set('Authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
        fetch(`https://wms-cloud.com/api/OrderPayment`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow' })
            //fetch(`http://localhost:8080/OrderPayment`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow'})
            .then((response) => {
                //response 200 baina uu shalgaad aldaa hevlene
                if (response.status < 200 || response.status >= 300) {
                    alert("Сервертэй холбогдоход алдаа гарлаа", { type: 'warning' });
                }
                return response.json();
            }).then(({ status }) => {
                if (status == 'Амжилттай') {
                    alert("Амжилттай", { type: 'success' });
                    navigate('/Orders');
                }
                else {

                    alert(status, { type: 'warning' });
                }
            })
            .catch((e) => {
                console.error(e);
                alert('Error:  алдаа гарлаа', { type: 'warning' });
            });
    }

    return (
        <>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Гүйлгээ буцаах">
                <DialogTitle>Төлбөр төлөх</DialogTitle>
                <DialogContent>
                    <div >
                    <Button color="primary"  onClick= {handlebelenSubmit} >Бэлэн төлсөн</Button>
                    <Button color="primary" onClick= {handlebankSubmit}>Банкаар төлсөн</Button>
                    <Button color="primary" disabled={isdisabled} onClick= {handlezeelSubmit}>Зээлээр төлсөн</Button>
                    <br/>
                    <TulburtuluhButtonToolbar onCancel={handleCloseClick} />
                    </div>
                </DialogContent>
            </Dialog>
            <Button color="primary" onClick={handleClick}>Төлбөр төлөх</Button>
            {}
        </>
    );
}

function TulburtuluhButtonToolbar({ onCancel, ...props }) {
    return (
        <div>
            <Button onClick={onCancel}>Буцах</Button>
        </div>

    );
}


export default TulburtuluhButton;