import { Button, Box, IconButton, TextField } from "@mui/material";
import React, { useState, useEffect, useRef, useLocalStorage } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import CustomerNameAuto from '../comp/custnameauto';
import CustTable from "../comp/custtable/custtable";
import OrderAddDetail from '../pages/orderAddDetail'
import '../css/product.css'
import '../css/orderadd.css'



const OrderAdd = ({token}) => {

    const [selected, setSelected] = useState({ cust_id: "Customer", cust_name: "Customer" });
    const [options, setOptions] = useState([]);
    const [itemdet, setItemDet] = useState([]);
    const [loading, setLoad] = useState(true);
    const [sname, setSname] = useState("");
    const [custrow, setCustrow] = useState([]);
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(20);
    const [allcont, setAllCount] = useState(0);
    const [items, setItems] = useState([]);
    const [sbarcode, setSBarcode] = useState("");
    const searchRefBarcode = useRef();
    const searchRefName = useRef();
    const SumAmount = useRef();
    const [modal, setmodal] = useState(false);

    const navigate = useNavigate();

    const custSearchName = (e) => {
        if (e.keyCode == 13) {
            setLoad();
            console.log("from enter: " + searchRefName.current.value);
            setSname(searchRefName.current.value);
            setStart(0);
            setEnd(20);
            // searchRefName.current.value = null
        }

        // console.log("from prod: " + e);
    }

    const rowClick = (e) => {
        // console.log("row: " + JSON.stringify(e));
        setCustrow(e);
        // console.log("custrow: " + custrow);
        setmodal(true);
        // navigate('/ProductMod', { state: { item: e } });

    }

    const clearRef = () => {
        searchRefBarcode.current.value="";
        searchRefName.current.value="";
        setSBarcode("");
        setSname("");
        searchRefBarcode.current.focus();
    }

    const getdataforname = (e) => {
        // console.log("changevallen" + e.target.value);
        // if (e.target.value.length > 0) {
        var tok = localStorage.getItem("tok");
        var url = "";
        if (e == null) {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + selected.cust_name;

        } else {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + e.target.value;
        }
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&prod_code=' + e.target.value;
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=5000';
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });

        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log("from here 2");
            // console.log(JSON.stringify(resp));
            var temp = [];
            resp.map((e) => {
                temp.push({ "cust_id": e.cust_id, "cust_name": e.cust_name });
            });
            // console.log("temp:" + JSON.stringify(temp));
            setOptions(temp);
        });
        // }
    }

    
    const prodColumns = [
        { field: 'prod_code', headerName: 'Баркод', minWidth: 130 },
        { field: 'prod_name', headerName: 'Бүтээгдхүүний нэр',  minWidth: 310, maxWidth: 500  },
        { type: 'number', field: 'available_bal', headerName: 'Too',  minWidth: 100 },
        { type: 'number', field: 'prod_price', headerName: 'Үнэ',  minWidth: 120 },
    ];

    const columns = [
        { field: 'ordet_prod_code', headerName: 'Баркод', minWidth: 130 },
        { field: 'ordet_prod_name', headerName: 'Бүтээгдхүүний нэр',  minWidth: 310, maxWidth: 500  },
        { type: 'number', field: 'ordet_too', headerName: 'Too',  minWidth: 100 },
        { type: 'number', field: 'ordet_price', headerName: 'Үнэ',  minWidth: 120 },
        {
            field: 'action2', headerName: 'Устгах', sortable: false, renderCell: (params) => {
                // return <button onClick={() => console.log(params)}> submit</button>
                return <IconButton onClick={() => handleDelete(params, 0)}> <DeleteOutlineIcon /></IconButton>
            }
        },
    ];

    const handleDelete = (e, i) => {
       setItemDet(itemdet.filter(function( obj ) {
        return obj.ordet_prod_code !== e.row.ordet_prod_code;
      }));
    }



    const custSearchBar = (e) => {
        if (e.keyCode == 13) {
            setLoad();
            console.log(searchRefBarcode.current.value);
            setSBarcode(searchRefBarcode.current.value);
            setStart(0);
            setEnd(20);
            // searchRefBarcode.current.value = null;
        }
    }

    const SaveData = () => {
        if(selected.cust_id!=="Customer")
            {
                setLoad(true);
                var req = {
                    "ord_cust_id":selected.cust_id,
                    "ord_tuluh_helber":"",
                    "ord_status":"Захиалга авсан",
                    "ord_sum_amount": SumAmount.current.value,
                    "ord_emp_id" : localStorage.getItem("uname"),
                    "ord_Details" : itemdet
                }
                console.log(req);

                var url = 'https://wms-cloud.com/api/addOrderNew';

                const request = new Request(url, {
                    method: 'POST',
                    body: JSON.stringify(req),
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                        'Access-Control-Allow-Headers': 'X-Total-Count'
                    })

                });

                fetch(request).then(
                    (response) => {
                        if (response.status == 200) {
                            
                            return response.json();
                        } else if (response.status == 500) {
                            alert("Алдаа гарлаа дахин оролдоно уу.");
                        } else {
                            localStorage.removeItem('tok');
                            //window.location.reload(false);
                            alert("Алдаа гарлаа.")
                        }
                    }
                ).then((resp) => {
                    console.log(resp);
                    //setItems(resp);
                    req.id=resp.id;
                    alert("Амжилттай " + resp.id + " "+ resp.status );
                    
                    navigate('/OrderMod', { state: { row: req, ordid: resp.id } });
                    setLoad(false);
                });

            }
            else{
                alert("Харилцагч сонгоно уу");
            }

    }

    const custRefresh = () => {
        var url = 'https://wms-cloud.com/api/getProducts?uldegdel=YES&_start=' + start + '&_end=' + end ;
        if (sname != "") {
            url = url + "&prod_name=" + sname;
        }
        if (sbarcode != "") {

            url = url + "&prod_code=" + sbarcode
        }

        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');
                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log(resp);
            setItems(resp);
            setLoad(false);

            if(resp.length===1)
                {
                    setCustrow(resp[0]);
                    // console.log("custrow: " + custrow);
                    setmodal(true);
                }
            else{
                setmodal(false)
            }
        });
    }


    useEffect(() => {
        
        custRefresh();
        let sum=0;
        for (let i = 0; i < itemdet.length; i++) {
            sum = sum+(parseFloat(itemdet[i].ordet_too)*parseFloat(itemdet[i].ordet_price));
            console.log("sum "+sum);
        }
          SumAmount.current.value=sum;
        


    }, [start,end, sname, sbarcode,itemdet]);

        return <div className="orderaddcont" >
            <div className ="orderAddTitle">
              <div><h3>Захиалга авах</h3></div>
              <div style={{ flex: "1", display: "flex", justifyContent: "flex-end"}}>
                <div style={{ width: "200px", marginRight: "10px" }}>
                    <CustomerNameAuto selected={selected} setProd={setSelected} options={options} getdataforbar={getdataforname}/></div>
                </div>
            </div>
            <div className="orderaddpart">
                    <div style={{ width: "100%", marginRight: "10px" }}>
                        {selected.cust_id !== 'Customer' ? <div style={{ height: "40px", marginRight: "10px" }}>Харилцагч: {selected.cust_id} - {selected.cust_name}</div> : <div></div> }
                            <div style={{width:"100%", height:"650px"}}>
                            <CustTable
                            columns={columns}
                            items={itemdet}
                            allcont={allcont}
                            celledit={false}
                            loading={loading}
                            rowid={(row) => 'orddetail' + row.ordet_prod_code} />
                            </div>
                        <div style={{ flex: "1", display: "flex", justifyContent: "flex-end"}}>
                            <div >
                            <Button style={{ marginRight: "3px", height: "40px" }} variant="outlined" onClick={SaveData} >Хадгалах</Button>
                            </div>
                            <div style={{ flex: "1", display: "flex", justifyContent: "flex-end"}}>
                                <TextField InputLabelProps={{ shrink: true }} type= "number" label="Нийт дүн" inputRef={SumAmount} color="secondary" focused />
                            </div>
                        

                        </div>
                    </div>
                    <div style={{ width: "100%", marginRight: "10px" }}>
                    <div>
                    <TextField style={{marginRight: "10px"}} inputRef={searchRefBarcode} size="small" label="Баркодоор хайх" onKeyDown={custSearchBar} />
                    <TextField style={{marginRight: "10px"}} inputRef={searchRefName} size="small" label="Бүтээгдхүүний нэр" onKeyDown={custSearchName} />
                    </div>
                    <div style={{width:"100%", height:"700px"}}>
                        <CustTable
                        columns={prodColumns}
                        items={items}
                        allcont={allcont}
                        celledit={false}
                        loading={loading}
                        rowClick={rowClick}
                        rowid={(row) => 'product' + row.prod_code} />
                    </div>
                    </div>
            </div>
            {modal ?
                <OrderAddDetail handleClose={setmodal}
                    token={token}
                    open={modal}
                    custrow={custrow}
                    handleRefresh={custRefresh}
                    handleModal={setmodal} 
                    items={itemdet} 
                    setitems={setItemDet}
                    clrRef={clearRef}/> : <></>
            }
            </div>


}

export default OrderAdd;