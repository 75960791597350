import { Component } from 'react';
// import { Button } from 'react-admin';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';


class DebitButtonDavhar extends Component {
    handleClick = () => {
        const { record } = this.props;

        console.log(record);
        const updatedRecord = JSON.stringify({ id: record.id, ord_status: "Зарлага гаргасан" });
        const token = localStorage.getItem('tok');
        const headers = new Headers({ Accept: 'application/json' });
        headers.set('Authorization', `Bearer ${token}`);
        headers.set('Content-Type', 'application/json');
        fetch(`https://wms-cloud.com/api/OrderDebitDavhar`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow' })
            //fetch(`http://localhost:8080/OrderDebitDavhar`, { method: 'POST', body: updatedRecord, headers: headers, redirect: 'follow'})
            .then((response) => {
                //response 200 baina uu shalgaad aldaa hevlene
                if (response.status < 200 || response.status >= 300) {
                    alert("Сервертэй холбогдоход алдаа гарлаа", { type: 'warning' });
                }
                return response.json();
            }).then(({ ord_status, ord_Details }) => {
                if (ord_status == 'Зарлага гаргасан') {
                    alert("Гүйлгээ амжилттай хийгдлээ", { type: 'success' });
                }
                else {
                    if (ord_status == 'Үлдэгдэл хүрэлцэхгүй байна') {
                        const ord_detail = JSON.stringify(ord_Details);
                        alert(ord_status + " " + ord_detail, { type: 'warning' });
                    }
                    else {
                        alert(ord_status, { type: 'warning' });
                    }
                }
                // status shalgaad aldaa hevlene
                //status Зарлага гаргасан gesen baival amjilttai gej hevlene
            })
            .catch((e) => {
                console.error(e);
                alert('Error: Гүйлгээ хийхэд алдаа гарлаа', { type: 'warning' });
            });
    }

    render() {
        return <Button color="primary" onClick={this.handleClick}>Зарлага гаргах (Анунгоо давхар урамшуулал)</Button>
    }
}

DebitButtonDavhar.propTypes = {
    push: PropTypes.func,
    record: PropTypes.object,
    showalertfication: PropTypes.func,
};

export default DebitButtonDavhar;