// import { Link } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import React, { useState } from 'react';
import "../css/sidemenu.css"

const SideItem = ({ title, icons, icononly, id, selected, setSelected, label }) => {

    const navigate = useNavigate();

    const handleClick = (title) => {
        setSelected(id);
        navigate(title);
    }

    return <div onClick={() => handleClick(title)} className={selected == id ? "sideitemselected" : "sideitem"} >
        <div style={{ color: "grey", marginTop: "3px", width: "50px", display: "flex", justifyContent: "center" }}>
            {icons}
        </div>
        {
            icononly ? <div style={{ color: "grey", flex: "1" }}>
                {label}
            </div> : <></>
        }

    </div>
}

export default SideItem;