
// import { Dashboard } from '@mui/icons-material';
import React, { useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MainHeader from '../comp/header';
import SideMenu from '../comp/sidemenu';
import "../css/home.css";
import Dashboard from './dashboard';
import Companies from './companies';
import Customers from './customers';
import Products from './products';
import Orders from './order';
import Transactions from './transactions';
import OrderRep from './orderrep';
import OrderRepAll from './orderrepall';
import LoanHistory from './loanhistory';
import LoanPayments from './laonpayments';
import Brands from './brands.js';
import Bonus from './bonus.js';
import ProductMod from './productmod';
import CustomerMod from './customermod';
import OrderMod from './ordermod';
import OrderAdd from './orderAdd';
import OrderDailyRep from './dailyreport';
import OrderGroupRep from './grouporderreport.js'
import Login from './login';
import Example from './example';
// import CustDDown from '../comp/custddown';



export default function Home({ token, uid, setToken }) {



    const [sidetoggle, setSide] = useState(true);

    console.log(token);
    return <div className="maincont">

        {/* {sidetoggle ? <SideMenu /> : <></>} */}
        <SideMenu icononly={sidetoggle} />
        <div className="contentcont">
            <MainHeader side={sidetoggle} handleSide={setSide} setToken={setToken} />
            <div className="container">
                <div className="ccont">
                    <Routes>
                        <Route path="/" element={<Dashboard token={token}/>} />
                        <Route path="/Dashboard" element={<Dashboard token={token} />} />
                        <Route path="/Companies" element={<Companies token={token} />} />
                        <Route path="/Brands" element={<Brands token={token} />} />
                        <Route path="/Bonus" element={<Bonus token={token} />} />
                        <Route path="/Customers" element={<Customers token={token} />} />
                        <Route path="/Products" element={<Products token={token} />} />
                        <Route path="/Orders" element={<Orders token={token} />} />
                        <Route path="/OrderMod" element={<OrderMod token={token} />} />
                        <Route path="/OrderAdd" element={<OrderAdd token={token} />} />
                        <Route path="/Transactions" element={<Transactions token={token} />} />
                        <Route path="/OrderRep" element={<OrderRep token={token} />} />
                        <Route path="/OrderDailyRep" element={<OrderDailyRep token={token} />} />
                        <Route path="/OrderGrpRep" element={<OrderGroupRep token={token} />} />
                        <Route path="/OrderRepAll" element={<OrderRepAll token={token} />} />
                        <Route path="/LoanHistory" element={<LoanHistory token={token} />} />
                        <Route path="/LoanPayments" element={<LoanPayments token={token} />} />
                        {/* <Route path="/CustDDown" element={<CustDDown token={token} />} /> */}
                        <Route path="/ProductMod" element={<ProductMod />} />
                        <Route path="/CustomerMod" element={<CustomerMod />} />
                        <Route path="/Example" element={<Example />} />

                    </Routes>
                </div>
            </div>
        </div>
    </div>
}

