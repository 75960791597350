import { Button } from '@mui/material';
import { format } from 'date-fns';
import React, { useState, useEffect } from 'react';
import * as XLSX from 'xlsx';
import CustTable from '../comp/custtable/custtable';


const OrderRepAll = ({ token }) => {
    const [data, setData] = useState([]);
    const [loading, setLoad] = useState(true);
    const [allcount, setAll] = useState(0);
    const [subrow, setSubrow] = useState(false);

    const columns = [
        { field: 'id', headerName: 'Код', },
        { field: 'prod_code', headerName: 'Баркод', },
        { field: 'prod_upc_code', headerName: 'UPC код', },
        { field: 'prod_mat_id', headerName: 'Mat код', },
        { field: 'prod_comp_id', headerName: 'Comp код', },
        { field: 'prod_name', headerName: 'Бүтээгдхүүний нэр', },
        { field: 'prod_size', headerName: 'Хэмжээ', },
        { field: 'prod_desc', headerName: 'Тайлбар', },
        { field: 'prod_num_in_box', headerName: 'Хайрцаг тоо', },
        { field: 'prod_price', headerName: 'Үнэ', },
        { field: 'prod_uldegdel', headerName: 'Агуулах үлдэгдэл', },
        { field: 'zah_too', headerName: 'Захиалга тоо', },
        { field: 'niit_too', headerName: 'Нийт тоо', },
        { field: 'sum_dun', headerName: 'Нийт үнэ', },
        { field: 'prod_tasarsan_eseh', headerName: 'Тасарсан эсэх', },
        { field: 'prod_rcre_time', headerName: 'Үүсгэсэн огноо', },
        { field: 'prod_unit', headerName: 'Нэгж', },
    ];

    useEffect(() => {

        const request = new Request('https://wms-cloud.com/api/OrderAllReportBalance?_start=0&_end=10', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        // console.log("token: " + token);
        console.log(
            JSON.stringify(request)
        );
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    // console.log(response.headers.get('X-Total-Count'));
                    setAll(response.headers.get('X-Total-Count'));
                    return response.json();
                }
            }
        ).then((resp) => {
            // console.log(JSON.stringify(resp));
            setData(resp);
            setLoad(false);

        });
    }, []);

    const downloadExcel = () => {
        setLoad(true);
        var exportdata = [];
        var btoken = localStorage.getItem('tok');
        const request = new Request('https://wms-cloud.com/api/OrderReportBalance?_start=0&_end=5000', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${btoken}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        // console.log("token: " + token);
        console.log(
            JSON.stringify(request)
        );
        fetch(request).then(
            (response) => {
                // console.log("response: " + JSON.stringify(response));
                if (response.status == 200) {
                    // console.log(response.headers.get('X-Total-Count'));
                    // setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log("resp: " + JSON.stringify(resp));
            console.log("resp body:" + resp);
            // console.log(format(new Date(), 'yyyy MM dd hh:mm:ss')
            // );

            var currdate = format(new Date(), 'yyyy_MM_dd_hhmmss');


            const workSheet = XLSX.utils.json_to_sheet(resp);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet");
            //Buffer
            let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
            //Binary string
            XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
            //Download
            XLSX.writeFile(workBook, "OrderReportAll" + currdate + ".xlsx");

            setLoad(false);

        });

    };

    return <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className="parenthead">
            <div>
                <h3> Захиалгын тайлан бүгд</h3>
            </div>
            <div style={{ display: "flex" }}>
                <Button style={{ marginRight: "3px", height: "40px" }} onClick={downloadExcel} variant="outlined">Excel -ээр татах</Button>
            </div>
        </div>
        {subrow ?
            <div style={{ height: "45px", border: "1px solid black", marginBottom: "5px", border: "1px solid rgba(224, 224, 224, 1)", display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "5px", borderRadius: "5px" }}>
                <Button variant="outlined" color="error">
                    Delete
            </Button>
            </div> : <></>}
        <div className="tablecont">
            <CustTable
                name={"Order Report All"}
                exportname={"order_rep_all"}
                columns={columns}
                items={data}
                rowid={(row) => 'ordrepall' + row.id}
                allcont={allcount}
                loading={loading}
                cellname={""}
                checkboxselect={true}
                subrow={subrow}
                setSubrow={setSubrow}
            />
        </div>

    </div>
}

export default OrderRepAll;