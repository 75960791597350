import logo from './logo.svg';
import './App.css';
import Home from './pages/home';
import { BrowserRouter } from 'react-router-dom';
import Login from './pages/login';
import React, { useState, useEffect } from 'react';

function App() {
  const [login, setLogin] = useState(false);
  const [btoken, setToken] = useState(localStorage.getItem('tok'));
  const [uname, setName] = useState(localStorage.getItem('uname'));
  // var btoken = "";
  // var uName = "";


  // const checkLogin = (username, password) => {
  //   console.log("check state: " + login + "  : " + username + " : " + password);
  //   // console.log(JSON.stringify({ username, password }));
  //   const request = new Request('https://wms-cloud.com/api/authenticate', {
  //     method: 'POST',
  //     // body: JSON.stringify({ username, password }),
  //     body: JSON.stringify({ "username": "E100", "password": "Mono123#" }),
  //     headers: new Headers({ 'Content-Type': 'application/json' })
  //   });
  //   fetch(request).then((response) => {
  //     if (response.status == 200) {
  //       return response.json()
  //       // setLogin(true);
  //     }
  //   }).then((res) => {
  //     console.log(res);
  //     // console(res.userid + " : " + res.firstName + " : " + res.token);
  //     // btoken = res.token;
  //     // uName = res.userid;
  //     localStorage.setItem('uname', username);
  //     console.log("setting: " + JSON.stringify(localStorage.getItem('username')));
  //     localStorage.setItem('pwd', password);
  //     setToken(res.token);
  //     // set
  //     setLogin(true);
  //   });
  // }

  // useEffect(() => {
  //   var clog = JSON.stringify(localStorage.getItem('uname'));
  //   console.log("clog: " + clog);
  // }, [])

  console.log("from app: " + btoken + " : " + uname);

  if (btoken != '' && btoken != null) {
    return <BrowserRouter>
      <Home token={btoken}
        setToken={setToken}
      //  uid={}
      />
    </BrowserRouter>
  } else {
    return <Login
      // checkLogin={checkLogin} 
      setLogin={setLogin}
      setToken={setToken}
    />
  }
}

export default App;
