import { useRef, useEffect, useState } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const CompFilter = ({ token, handleComp }) => {
    const [data, setData] = useState([]);
    const [curr, setCur] = useState("");

    useEffect(() => {
        console.log(token);
        const url = "https://wms-cloud.com/api/Company";
        const request = new Request(url, {
            method: 'GET',

            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        // console.log("token" + token);
        console.log(JSON.stringify(request)
        );
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    console.log("header:" + response.headers.get('X-Total-Count')
                    );
                    // setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                }
            }
        ).then((resp) => {
            console.log(resp);
            setData(resp);

            // setLoading(false);
        });
    }, [])

    const handleChange = (e) => {
        console.log(e.target.value);
        setCur(e.target.value);
        handleComp(e.target.value);
    }

    return <div style={{ width: "150px" }}>
        <FormControl fullWidth size="small" >
            <InputLabel id="demo-simple-select-label">Компани</InputLabel>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={curr}
                label="Company"
                onChange={handleChange}
            >
                <MenuItem key={""} value={""}>Бүгд</MenuItem>
                {
                    
                    data.map((e) => <MenuItem key={e.com_id} value={e.com_id}>{e.com_name}</MenuItem>)
                    
                }
            </Select>
        </FormControl>
    </div>
}

export default CompFilter;