import React, { useState, useEffect } from 'react';
import CustTable from '../comp/custtable/custtable';
import CustomerNameAuto from '../comp/custnameauto';
import LoanPaymentAdd from './loanpaymentAdd';
import "../css/transactions.css";
import { Button, CircularProgress, TextField } from '@mui/material';


const LoanPayments = ({ token }) => {

    const [items, setItems] = useState([]);
    const [loading, setLoad] = useState(true);
    const [allcount, setAll] = useState(0);
    const [selected, setSelected] = useState({ cust_id: "Customer", cust_name: "Customer" });
    const [options, setOptions] = useState([]);
    const [[start, end], setSend] = useState([0, 20]);
    const [addmodal, setAddModal] = useState(false);

    const columns = [
        // { key: 'id', name: "ID" },
        // { key: 'lap_emp_id', name: "Emp ID" },
        // { key: 'lap_cust_id', name: "Customer ID" },
        // { key: 'lap_tulsun_helber', name: "Type" },
        // { key: 'lap_date', name: "Data" },
        // { key: 'lap_status', name: "Status" },
        // { key: 'lap_amount', name: "Amount" },
        // { key: 'lap_tran_desc', name: "Tran/Desc" },

        { field: 'id', headerName: 'Дугаар', },
        { field: 'lap_cust_name', headerName: 'Харилцагч' , width: 220 },
        { type: 'number', field: 'lap_amount', headerName: 'Дүн', },
        { field: 'lap_tulsun_helber', headerName: 'Төлсөн хэлбэр',  width: 130  },
        { field: 'lap_tran_desc', headerName: 'Гүйлгээний утга', width: 200 },
        { field: 'lap_emp_id', headerName: 'Борлуулагч', },
        { field: 'lap_date', headerName: 'Огноо', },

    ]

    useEffect(() => {
        var url = 'https://wms-cloud.com/api/LoanPayment?_start=' + start + '&_end=' + end ;
        //var url = 'http://localhost:8080/LoanPayment?_start=' + start + '&_end=' + end ;

        if (selected.cust_id != "Customer") {
            url = url + '&cust_id=' + selected.cust_id;
        }
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        // console.log("token: " + token);
        console.log(
            JSON.stringify(request)
        );
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    // console.log(response.headers.get('X-Total-Count'));
                    setAll(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log(JSON.stringify(resp));
            setItems(resp);
            setLoad(false);
        });
    }, [selected,start,end]);

    const DataRefresh = () => {
        var url = 'https://wms-cloud.com/api/LoanPayment?_start=' + start + '&_end=' + end ;
        //var url = 'http://localhost:8080/LoanPayment?_start=' + start + '&_end=' + end ;

        if (selected.cust_id != "Customer") {
            url = url + '&cust_id=' + selected.cust_id;
        }
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        // console.log("token: " + token);
        console.log(
            JSON.stringify(request)
        );
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    // console.log(response.headers.get('X-Total-Count'));
                    setAll(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log(JSON.stringify(resp));
            setItems(resp);
            setLoad(false);
        });
    }

    const handlePage = (e) => {
        setSend([e * 20, (e * 20 + 20)]);
    }

    const getdataforname = (e) => {
        // console.log("changevallen" + e.target.value);
        // if (e.target.value.length > 0) {
        var tok = localStorage.getItem("tok");
        var url = "";
        if (e == null) {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + selected.cust_name;

        } else {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + e.target.value;
        }
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&prod_code=' + e.target.value;
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=5000';
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });

        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log("from here 2");
            // console.log(JSON.stringify(resp));
            var temp = [];
            resp.map((e) => {
                temp.push({ "cust_id": e.cust_id, "cust_name": e.cust_name });
            });
            // console.log("temp:" + JSON.stringify(temp));
            setOptions(temp);
        });
        // }
    }

    return <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className="parenthead" >
            <h3>Зээлийн төлөлт</h3>
        
        <div style={{ display: "flex" }}>
        <div style={{ width: "200px", marginRight: "3px" }}>
                    {/* <TextField style={{ marginLeft: "3px" }} inputRef={searchcust_id}  size="small" label="Харилцагч" onKeyDown={cust_idChange} /> */}   
                    <CustomerNameAuto selected={selected} setProd={setSelected} options={options} getdataforbar={getdataforname}/>
                </div>

        
        <Button style={{ marginRight: "3px", height: "40px" }} variant="outlined" onClick={setAddModal} >Төлбөр төлөх</Button>
        </div>
        </div>
        <div className="tablecont">
            <CustTable
                name={"Loan Payments"}
                columns={columns}
                items={items}
                rowid={(row) => 'lp' + row.id}
                allcont={allcount}
                handlePage={handlePage}
                loading={loading}

            />
        </div>
        {addmodal ?
                <LoanPaymentAdd
                    handleClose={setAddModal}
                    token={token}
                    open={addmodal}
                    handleRefresh={DataRefresh}
                /> : <></>
        }
    </div>
    
}

export default LoanPayments;