import { Button, Divider, CircularProgress, InputLabel, Select, MenuItem, TableHead, TableRow, TextField } from '@mui/material';
import { useRef, useEffect, useState } from 'react';
import '../css/prodmodal.css'
import ProductBarcodeAuto from "../comp/prodbarcodeauto";
import CustomerNameAuto from '../comp/custnameauto';

const LoanPaymentAdd = ({ handleClose, token, handleRefresh }) => {

    const [prodload, setProdload] = useState(false);
    const [selected, setSelected] = useState({ cust_id: "Customer", cust_name: "Customer" });
    const [options, setOptions] = useState([]);
    const lap_amount = useRef();
    const lap_tran_desc = useRef();
    const [helber, setHelber] = useState("");


    const handleEscape = (e) => {
        if (e.keyCode == 27) {
            handleClose(false);
        }

    }

    useEffect(() => {
        
        window.addEventListener('keyup', handleEscape);
        return () => {
            window.removeEventListener('keyup', handleEscape);
        }
    }, [])


    const handleClick = () => {
        handleClick(false);
    }

    const handleSubmit = () => {
        setProdload(true);
        var req = {
            'lap_emp_id': localStorage.getItem('uname'),
            'lap_cust_id': selected.cust_id,
            'lap_amount': parseFloat(lap_amount.current.value),
            'lap_tran_desc': lap_tran_desc.current.value,
            'lap_tulsun_helber': helber,
        }

        console.log("req "+JSON.stringify(req));

        const request = new Request('https://wms-cloud.com/api/LoanPayment/', {
            method: 'POST',
            body: JSON.stringify(req),
            headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${token}` })
        });

        fetch(request).then((response) => {
            if (response.status === 200) {
                console.log("aldaa garaagu");
                alert("Амжилттай");
                setProdload(false);
                handleClose(false);
                handleRefresh();

            } else {
                console.log("aldaa garsan" + response.status);
                alert("Ажилтгүй дахин оролдоно уу. " + JSON.stringify(response));
                setProdload(false);
            }
        });


    }

    
    const getdataforname = (e) => {
        // console.log("changevallen" + e.target.value);
        // if (e.target.value.length > 0) {
        var tok = localStorage.getItem("tok");
        var url = "";
        if (e == null) {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + selected.cust_name;

        } else {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + e.target.value;
        }
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&prod_code=' + e.target.value;
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=5000';
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });

        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log("from here 2");
            // console.log(JSON.stringify(resp));
            var temp = [];
            resp.map((e) => {
                temp.push({ "cust_id": e.cust_id, "cust_name": e.cust_name });
            });
            // console.log("temp:" + JSON.stringify(temp));
            setOptions(temp);
        });
        // }
    }

    const handleChange = (e) => {
        setHelber(e.target.value);
    }

    return <div className="custmodal" >
        <div className="mcont">
            <div className="mheader">
                Зээл Төлбөр төлөх
            <button onClick={() => handleClose()}>x</button>
            </div>
            <Divider />
            <div className="mccont">

                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                    <CustomerNameAuto selected={selected} setProd={setSelected} options={options} getdataforbar={getdataforname}/>
                    </div>
                </div>
                
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={lap_amount} label="Төлсөн дүн" type="number" fullWidth ></TextField>
                    </div>
                </div>
                <InputLabel id="demo-simple-select-label">Төлсөн хэлбэр</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={helber}
                            label="Хэлбэр"
                            onChange={handleChange}
                        >
                            {/* {items.map(e => <MenuItem key={e.id} value={e.id}>{e.brand_name}</MenuItem>)} */}
                            <MenuItem key={"Бэлэн"} value={"Бэлэн"}>{"Бэлэн"}</MenuItem>)
                            <MenuItem key={"Банк"} value={"Банк"}>{"Банк"}</MenuItem>)
                        </Select>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={lap_tran_desc} label="Гүйлгээний утга" type="text" fullWidth ></TextField>
                    </div>
                </div>

            </div>
            <div className="mfooter">
                <Button onClick={handleSubmit}>Хадгалах</Button>
                <Button onClick={() => handleClose()}>Буцах</Button>
            </div>
            {prodload ?
                <div className="prodload">
                    <CircularProgress />
                </div> : ""}
        </div>
    </div >
}

export default LoanPaymentAdd;