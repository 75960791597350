import { Button, IconButton, TextField, CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CustTable from "../comp/custtable/custtable";
import { DataGrid, GridColDef, GridApi, GridCellValue } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import '../css/ordermod.css'
import DebitButton from "../comp/custbutton/debitbutton";
import DeliveryButton from "../comp/custbutton/Hurgeltbutton";
import RevDeliveryButton from "../comp/custbutton/RevHurgelButton";
import RevLoanButton from "../comp/custbutton/RevLoanButton";
import DebitButtonDavhar from "../comp/custbutton/debitbuttondavhar";
import OrderRevButton from "../comp/custbutton/orderrevbutton";
import TulburtuluhButton from "../comp/custbutton/Zeelbutton";
import TuluhHelberButton from "../comp/custbutton/tuluhhelberbutton";
import PadaanButton from "../comp/custbutton/padaanbutton";
import PadaanButtonDavhar from "../comp/custbutton/padaanbuttondavkhar";
import OrderModal from "./ordermodal";
import OrderUpdate from "./orderupdate";



const OrderMod = ({ token, ordid }) => {
    const location = useLocation();

    const [[start, end], setSEnd] = useState([0, 10]);
    const [items, setItems] = useState([]);
    const [itemdet, setItemDet] = useState([]);
    const [loading, setLoad] = useState(true);
    const [addmodal, setAddModal] = useState(false);
    const [editmodal, setEditModal] = useState(false);
    const [custrow, setCustrow] = useState([]);
    const [refresh, setRefresh] = useState("");
    const [Barcode, setBarcode] = useState("");
    const [prodload, setProdLoad] = useState(false);


    const rid = useRef();
    const rcid = useRef();
    const rorddate = useRef();
    const rpay = useRef();
    const rstat = useRef();
    const rsum = useRef();
    const rempid = useRef();
    const rcre = useRef();
    const prodCode =useRef();

    const submitRow = (e, i) => {
        console.log("rwo: " + JSON.stringify(e.row) + "  : " + i);
    }

    const handleRefresh = () => {
        setRefresh("ref");
        window.location.reload(false);
    }

    const handleSubmit = (e, i) => {
        setLoad(true);
        var req = {
            "ordet_id": e.row.ordet_id,
            "ordet_ord_id": e.row.ordet_ord_id,
            "ordet_option": i,
            "ordet_prod_code": e.row.ordet_prod_code,
            "ordet_prod_name": e.row.ordet_prod_name,
            "ordet_too": e.row.ordet_too,
            "ordet_price": e.row.ordet_price,
            "ordet_prod_price": e.row.ordet_prod_price
            // "ordet_cre_time": e.row.ordet_cre_time
        };

        console.log(req);

        // setLoad(false);

        const request = new Request('https://wms-cloud.com/api/OrderdetailsUpdate/', {
            method: 'POST',
            body: JSON.stringify(req),
            headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${token}` })
        });

        fetch(request).then((response) => {
            if (response.status == 200) {
                console.log("amjilttai " + JSON.stringify(response));
                setLoad(false);
                return response.json();

            } else {
                console.log("Амжилтгүй: " + response.status);
                setLoad(false);
            }
        }).then(resp => {
            console.log(JSON.stringify(resp));
            alert("Амжилттай: " + resp.ordet_prod_name);
            setLoad(false);
            handleRefresh();
        });


        console.log(JSON.stringify(req));
    }

    const handleDelete = (e, i) => {
        setLoad(true);
        var req = {
            "ordet_id": e.row.ordet_id,
            "ordet_ord_id": e.row.ordet_ord_id,
            "ordet_option": i,
        };

        console.log(req);

        // setLoad(false);

        const request = new Request('https://wms-cloud.com/api/OrderdetailsUpdate/', {
            method: 'POST',
            body: JSON.stringify(req),
            headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${token}` })
        });

        fetch(request).then((response) => {
            if (response.status == 200) {
                console.log("amjilttai " + JSON.stringify(response));
                setLoad(false);
                return response.json();

            } else if (response.status == 500) {
                alert("Алдаа гарлаа дахин оролдоно уу.");
                setLoad(false);
            } else {
                // localStorage.getItem('tok')
                localStorage.removeItem('tok');

                window.location.reload(false);
                alert("Алдаа гарлаа.")
            }
        }).then(resp => {
            console.log(JSON.stringify(resp));
            alert("Амжилттай: " + resp.ordet_prod_name);
            setLoad(false);
            handleRefresh();
        });


        console.log(JSON.stringify(req));
    }

    const rowClick = (e) => {

        setCustrow(e);
        console.log(e);

        setEditModal(true);


    }
    const barcodeChange = (e) =>{
        if (e.keyCode == 13) {
        setBarcode(prodCode.current.value);
        }
    }

    const columns = [
        { field: 'ordet_prod_code', headerName: 'Баркод', minWidth: 150 },
        { field: 'ordet_prod_name', headerName: 'Бүтээгдхүүний нэр',  minWidth: 500, maxWidth: 800  },
        { type: 'number', field: 'ordet_too', headerName: 'Too',  minWidth: 150 },
        { type: 'number', field: 'ordet_price', headerName: 'Үнэ',  minWidth: 150 },
        { field: 'ordet_cre_time', headerName: 'Үүсгэсэн огноо',   minWidth: 150 },
        {
            field: 'action2', headerName: 'Устгах', sortable: false, renderCell: (params) => {
                // return <button onClick={() => console.log(params)}> submit</button>
                return <IconButton onClick={() => handleDelete(params, 0)}> <DeleteOutlineIcon /></IconButton>
            }
        },
    ];

    useEffect(() => {
        rid.current.value = location.state.row.id;

        rcid.current.value = location.state.row.ord_cust_id;
        rorddate.current.value = location.state.row.ord_date;
        rpay.current.value = location.state.row.ord_tuluh_helber;
        rstat.current.value = location.state.row.ord_status;
        rsum.current.value = location.state.row.ord_sum_amount;
        rempid.current.value = location.state.row.ord_emp_id;
        rcre.current.value = location.state.row.ord_cre_time;

        const request = new Request('https://wms-cloud.com/api/Orders/' + location.state.row.id, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        // console.log("token: " + token);
        // console.log(
        //     JSON.stringify(request)
        // );
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    console.log(response.headers.get('X-Total-Count'));
                    // setAllCount(response.headers.get('X-Total-Count'));
                    setLoad(false);
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            // console.log("asd: " + JSON.stringify(resp.ord_Details));
            setItems(resp);
        });
        var url='https://wms-cloud.com/api/getOrderDetails?ordet_ord_id=' + location.state.row.id;
        //var url='http://localhost:8080/getOrderDetails?ordet_ord_id=' + location.state.row.id;

        if (Barcode != "") {
            url = url + "&ordet_prod_code=" + Barcode;
        }

        const reqdetails = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        // console.log("token: " + token);
        // console.log(
        //     JSON.stringify(request)
        // );
        fetch(reqdetails).then(
            (response) => {
                if (response.status == 200) {
                    console.log(response.headers.get('X-Total-Count'));
                    // setAllCount(response.headers.get('X-Total-Count'));
                    setLoad(false);
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');
                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            // console.log("asd: " + JSON.stringify(resp.ord_Details));
            setItemDet(resp);
        });
    }, [refresh, Barcode])

    return <div className="ordermodcont" >
        Захиалга
            <div>

            { prodload ? "" : <DebitButton token={token} record={items} load={setProdLoad} /> }
            { prodload ? "" : <OrderRevButton record={items} /> }
            { prodload ? "" : <PadaanButton record={items} /> }
            { prodload ? "" : <DeliveryButton token={token} record={items} /> }
            { prodload ? "" : <TulburtuluhButton record={items} /> }
            { prodload ? "" : <TuluhHelberButton record={items} token={token} /> }
            {localStorage.getItem('role').includes('116') ? <RevDeliveryButton token={token} record={items} /> : ""}
            {localStorage.getItem('role').includes('117') ? <RevLoanButton token={token} record={items} /> : ""}
            
        </div>
        <div className="orderpart">
            <div>
                <TextField size="small" inputRef={rid} label="Захиалгын дугаар" fullWidth />
            </div>
            <div>
                <TextField size="small" inputRef={rcid} label="Харилцагч" fullWidth />
            </div>
            <div>
                <TextField size="small" inputRef={rorddate} label="Огноо" fullWidth />
            </div>
            <div>
                <TextField size="small" inputRef={rpay} label="Төлөх хэлбэр" fullWidth />
            </div>
            <div>
                <TextField size="small" inputRef={rstat} label="Төлөв" fullWidth />
            </div>
            <div>
                <TextField size="small" inputRef={rsum} label="Нийт дүн" fullWidth />
            </div>
            <div>
                <TextField size="small" inputRef={rempid} label="Борлуулагч" fullWidth />
            </div>
            <div>
                <TextField size="small" inputRef={rcre} label="Үүссэн огноо" fullWidth />
            </div>


        </div>
        <div style={{ width: "100%", display: "flex"}}>
            <div style={{ width: "100%", display: "flex" }}>
                <TextField size="small" inputRef={prodCode} label="Баркод хайх" onKeyDown={barcodeChange}/>
            </div>
            <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                    <Button onClick={() => setAddModal(true)}>Бүтээгдхүүн нэмэх</Button>
                    
                </div>

        </div>
        <div className="ordertable" >
            <CustTable
                columns={columns}
                items={itemdet}
                celledit={false}
                loading={loading}
                rowClick={rowClick}
                rowid={(row) => 'orddet' + row.ordet_id} />

        </div>
        {addmodal ? <OrderModal handleClose={setAddModal} ordid={location.state.row.id} handleRefresh={handleRefresh} ></OrderModal> : <></>}
        {editmodal ? <OrderUpdate handleClose={setEditModal} ordid={location.state.row.id} custrow={custrow} handleRefresh={handleRefresh} ></OrderUpdate> : <></>}
        {prodload ?
                <div className="prodload">
                    <CircularProgress />
                </div> : ""}
    </div>
}

export default OrderMod;