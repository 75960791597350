import { Autocomplete, Button, TextField } from '@mui/material';
import { tr } from 'date-fns/locale';
import React, { useState, useEffect } from 'react';
import CustTable from '../comp/custtable/custtable';
import RefreshIcon from '@mui/icons-material/Refresh';
import CompanyMod from './companymod';
import CompanyAuto from '../comp/companyauto';
import ProductAuto from '../comp/prodbarcodeauto';

const Companies = ({ token }) => {
    const [items, setItems] = useState([]);
    const [[start, end], setSEnd] = useState([0, 10])
    const [sstr, setSearch] = useState();
    const [allcont, setAllCount] = useState(0);
    const [loading, setLoad] = useState(true);
    const [modal, setmodal] = useState(false);
    const [custrow, setcustrow] = useState([]);


    const columns = [
        // { key: 'id', name: "ID" },
        // { key: 'com_id', name: "Company ID" },
        // { key: 'com_name', name: "Company Name" },
        // { key: 'com_reg_num', name: "Com/ Register" },
        // { key: 'com_phone', name: "Phone" },
        // { key: 'com_account', name: "Account" },
        { field: 'id', headerName: 'Код', },
        { field: 'com_id', headerName: 'Компани код', },
        { field: 'com_name', headerName: 'Компани нэр', width: 200 },
        { field: 'com_reg_num', headerName: 'Регистр', },
        { field: 'com_phone', headerName: 'Утасны дугаар', },
        { field: 'com_account', headerName: 'Данс', },
        { field: 'com_bonus_prcnt', headerName: 'Хөнгөлөлт хувь',  width: 200},
        { field: 'com_bonus_amt', headerName: 'Хөнгөлөлт эдлэх дүн (дээш байвал)', width: 250 },
        { field: 'com_bonus_helber', headerName: 'Хөнгөлөлт эдлэх хэлбэр(Зөвхөн бэлэн)', width: 250 },
    ];

    useEffect(() => {
        var url = 'https://wms-cloud.com/api/Company?com_id=' + sstr;
        //var url = 'http://localhost:8080/Company?com_id=' + sstr;
        const request = new Request(url, {
            method: 'GET',
            // body: JSON.stringify({ username, password }),
            // headers:
            //  new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${token}` })
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        // console.log("token: " + token);
        // console.log(
        //     JSON.stringify(request)
        // );
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    console.log(response.headers.get('X-Total-Count'));
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            // console.log(JSON.stringify(resp));
            setItems(resp);
            setLoad(false);
        });

    }, [start, end, sstr])

    const handleRow = (e) => {
        console.log(e);
    }

    const handlePage = () => {

    }

    const handleRowClick = (e) => {
        console.log(e);
        setcustrow(e);
        setmodal(true);
    }

    const custRefresh = () => {
        window.location.reload(false);
    }



    return <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className="parenthead">
            <div>
                <h3>Компани</h3>
            </div>
            {/* <CompanyAuto selected={setSearch} /> */}
            {/* <ProductAuto selected={setSearch} /> */}
            <Button variant="outlined" style={{ marginLeft: "3px", height: "40px" }} onClick={() => {
                window.location.reload(false);
            }}> <RefreshIcon /> </Button>
        </div>
        <div className="tablecont">
            <CustTable
                name={"Companies"}
                columns={columns}
                items={items}
                allcont={allcont}
                handlePage={handlePage}
                rowClick={handleRowClick}
                loading={loading} />
        </div>
        {modal ?
            <CompanyMod
                handleClose={setmodal}
                token={token}
                open={modal}
                custrow={custrow}
                handleModal={setmodal}
                handleRefresh={custRefresh}
            /> : <></>
        }

    </div>
}

export default Companies;