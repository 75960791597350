import { Button, CircularProgress, Divider, Modal, Table, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { useRef, useEffect, useState } from 'react';
import BrandFilter from '../comp/brandfilter';
import Companydown from '../comp/companydown';
import '../css/prodmodal.css'

const ProductAdd = ({ handleClose, token, open, custrow, handleModal, handleRefresh }) => {

    const [prodload, setProdload] = useState(false);
    const [pcompid, setCompany] = useState("");
    const pcode = useRef();
    const pupccode = useRef();
    const pmatid = useRef();
    const pname = useRef();
    const psize = useRef();
    const pdesc = useRef();
    const pnuminbox = useRef();
    const pprice = useRef();
    const puld = useRef(); 
    const [brand, setBrand] = useState("");
    const ptas = useRef();
    const pcre = useRef();
    const punit = useRef();
    const pbonusp = useRef();
    const pbonusu = useRef();

    const handleEscape = (e) => {
        if (e.keyCode == 27) {
            handleClose(false);
        }

    }

    useEffect(() => {
        window.addEventListener('keyup', handleEscape);
        return () => {
            window.removeEventListener('keyup', handleEscape);
        }
    }, [])


    const handleClick = () => {
        handleClick(false);
    }

    const handleSubmit = () => {
        setProdload(true);
        if(pcompid == "")
        {
            alert("Компани сонгоогүй байна.");
            setProdload(false);
        }
        else
        {
            var req = {
                'prod_code': pcode.current.value,
                'prod_size': psize.current.value,
                'prod_upc_code': pupccode.current.value,
                'prod_mat_id': pmatid.current.value,
                'prod_comp_id': pcompid,
                'prod_name': pname.current.value,
                'prod_size': parseFloat(psize.current.value),
                'prod_desc': pdesc.current.value,
                'prod_num_in_box': parseInt(pnuminbox.current.value),
                'prod_price': parseFloat(pprice.current.value),
                'prod_uldegdel': parseInt(puld.current.value),
                'prod_brand_id': brand,
                'prod_tasarsan_eseh': ptas.current.value,
                'prod_unit': punit.current.value,
                'prod_bonus_prcnt': parseFloat(pbonusp.current.value),
                'prod_bonus_unit': parseInt(pbonusu.current.value),
            }

            console.log(JSON.stringify(req));
            console.log('https://wms-cloud.com/api/Products/' + pcode.current.value);

            const request = new Request('https://wms-cloud.com/api/Products/', {
                method: 'POST',
                body: JSON.stringify(req),
                headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${token}` })
            });

            fetch(request).then((response) => {
                if (response.status === 200) {
                    console.log("aldaa garaagu");
                    alert("Амжилттай");
                    setProdload(false);
                    handleClose(false);
                    handleRefresh();

                } else {
                    console.log("aldaa garsan" + response.status);
                    alert("Ажилтгүй дахин оролдоно уу. " + JSON.stringify(response));
                    setProdload(false);
                }
            });
        }


    }
    return <div className="custmodal" >
        <div className="mcont">
            <div className="mheader">
                Шинэ бараа нэмэх
            <button onClick={() => handleClose()}>x</button>
            </div>
            <Divider />
            <div className="mccont">

                <div className="crow">
                    {/* <div style={{ width: "", paddingRight: "10px" }}>
                        <TextField inputRef={pcode} label="Product code" fullWidth ></TextField>
                    </div> */}
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={pcode} label="Баркод" fullWidth ></TextField>
                    </div>
                </div>
                {/* <Divider className="divide" /> */}
                <div className="crow">
                    <div style={{ width: "350px", paddingRight: "10px" }}>
                        <TextField inputRef={pupccode} label="UPC Код" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "", paddingRight: "10px" }}>
                        <TextField inputRef={pmatid} label="Material код" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "", paddingRight: "10px" }}>
                        <Companydown token={token} handleComp={setCompany} />
                    </div>
                </div>
                {/* <Divider className="divide" /> */}
                <div className="crow">
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={pname} label="Бүтээгдхүүний нэр" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "25%", paddingRight: "10px" }}>
                        <TextField inputRef={psize} label="Хэмжээ" type="number" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "25%", paddingRight: "10px" }}>
                        <TextField inputRef={punit} label="Нэгж  KG/L" fullWidth ></TextField>
                    </div>
                </div>
                {/* <Divider className="divide" /> */}
                <div className="crow">
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        {/* <TextField inputRef={pnuminbox} label="Brand" type="number" fullWidth ></TextField> */}
                        <BrandFilter token={token} handleBrand={setBrand} />
                    </div>

                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={pnuminbox} label="Хайрцаг дахь тоо" type="number" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={pdesc} label="Тайлбар" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "25%", paddingRight: "10px" }}>
                        <TextField inputRef={pprice} label="Үнэ" type="number" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "25%", paddingRight: "10px" }}>
                        <TextField inputRef={puld} label="Үлдэгдэл" type="number" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={ptas} label="Тасарсан эсэх " fullWidth ></TextField>
                    </div>

                </div>
                <div className="crow">

                    {/* <div style={{ width: "60%", paddingRight: "10px" }}>
                        <TextField inputRef={pcre} label="Publication " fullWidth ></TextField>
                    </div> */}
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={pbonusp} label="Урамшуулал хувь" fullWidth type="number" ></TextField>
                    </div>
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={pbonusu} label="Урамшуулал тоо (дээш)" fullWidth type="number" ></TextField>
                    </div>
                </div>

            </div>
            <div className="mfooter">
                <Button onClick={handleSubmit}>Хадгалах</Button>
                <Button onClick={() => handleClose()}>Буцах</Button>
                {/* <button>Save</button> */}
                {/* <button>Cancel</button> */}
            </div>
            {prodload ?
                <div className="prodload">
                    <CircularProgress />
                </div> : ""}
        </div>
    </div >
}

export default ProductAdd;