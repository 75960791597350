import { Button, CircularProgress, Divider,  TextField } from '@mui/material';
import { useRef, useEffect, useState } from 'react';
import BrandFilter from '../comp/brandfilter';
import Companydown from '../comp/companydown';
import '../css/prodmodal.css'

const ProductModal = ({ handleClose, token, open, custrow, handleModal, handleRefresh }) => {

    const [prodload, setProdload] = useState(false);
    const pcode = useRef();
    // const pcompid = useRef();
    const [pcompid, setComp] = useState("");
    const pupccode = useRef();
    const pmatid = useRef();
    const pname = useRef();
    const psize = useRef();
    const pdesc = useRef();
    const pnuminbox = useRef();
    const pprice = useRef();
    const puld = useRef();
    // const pav = useRef();
    const [brand, setBrand] = useState("");
    const ptas = useRef();
    const punit = useRef();
    const pbonusp = useRef();
    const pbonusu = useRef();

    // useEffect(() => {
    //     setBrand(custrow.prod_brand_id);
    // });

    useEffect(() => {
        pcode.current.value = custrow.prod_code;
        setComp(custrow.prod_comp_id);
        pupccode.current.value = custrow.prod_upc_code;
        pmatid.current.value = custrow.prod_mat_id;
        pname.current.value = custrow.prod_name;
        psize.current.value = custrow.prod_size;
        pdesc.current.value = custrow.prod_desc;
        pnuminbox.current.value = custrow.prod_num_in_box;
        pprice.current.value = custrow.prod_price;
        puld.current.value = custrow.prod_uldegdel;
        ptas.current.value = custrow.prod_tasarsan_eseh;
        // pcre.current.value = custrow.prod_rcre_time;
        setBrand(custrow.prod_brand_id);
        punit.current.value = custrow.prod_unit;
        pbonusp.current.value = custrow.prod_bonus_prcnt;
        pbonusu.current.value = custrow.prod_bonus_unit;
    }, [])

    const handleEscape = (e) => {
        // console.log(e);
        if (e.keyCode == 27) {
            // console.log("escape clicked");
            handleClose(false);
        }

    }

    useEffect(() => {
        // document.addEventListener(KEY_EVENT_TYPE, handleEscape, false);
        window.addEventListener('keyup', handleEscape);
        return () => {
            window.removeEventListener('keyup', handleEscape);
        }
    }, [handleEscape])


    const handleClick = () => {
        console.log("open: " + open);
        if (open) {
            handleModal(false);
        }
    }

    const handleSubmit = () => {
        setProdload(true);
        var req = {
            'prod_code': pcode.current.value,
            'prod_upc_code': pupccode.current.value,
            'prod_mat_id': pmatid.current.value,
            'prod_comp_id': pcompid,
            'prod_name': pname.current.value,
            'prod_size': psize.current.value,
            'prod_desc': pdesc.current.value,
            'prod_num_in_box': pnuminbox.current.value,
            'prod_price': pprice.current.value,
            'prod_uldegdel': puld.current.value,
            'prod_brand_id': brand,
            'prod_tasarsan_eseh': ptas.current.value,
            //'prod_rcre_time': pcre.current.value,
            'prod_unit': punit.current.value,
            'prod_bonus_prcnt': pbonusp.current.value,
            'prod_bonus_unit': pbonusu.current.value,
        }

        console.log(JSON.stringify(req));
        console.log('https://wms-cloud.com/api/Products/' + pcode.current.value);

        const request = new Request('https://wms-cloud.com/api/Products/' + pcode.current.value, {
            method: 'PUT',
            body: JSON.stringify(req),
            headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${token}` })
        });

        fetch(request).then((response) => {
            if (response.status == 200) {
                console.log("aldaa garaagu");
                console.log(response.body);
                setProdload(false);
                handleClose(false);
                handleRefresh();

            } else {
                console.log("aldaa garsan" + JSON.stringify(response));
                alert("Амжилттай");
                setProdload(false);
                // handleClose(false);
            }
        });


    }
    return <div className="custmodal" >
        <div className="mcont">
            <div className="mheader">
                Барааны мэдээлэл засах
            <button onClick={() => handleClick()}>x</button>
            </div>
            <Divider />
            <div className="mccont">

                <div className="crow">
                    {/* <div style={{ width: "", paddingRight: "10px" }}>
                        <TextField inputRef={pcode} label="Product code" fullWidth ></TextField>
                    </div> */}
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={pcode} label="Баркод" fullWidth ></TextField>
                    </div>
                </div>
                {/* <Divider className="divide" /> */}
                <div className="crow">
                    <div style={{ width: "350px", paddingRight: "10px" }}>
                        <TextField inputRef={pupccode} label="UPC код" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "", paddingRight: "10px" }}>
                        <TextField inputRef={pmatid} label="Material код" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "", paddingRight: "10px" }}>
                        <Companydown token={token} handleComp={setComp} compid={pcompid} />
                    </div>
                </div>
                {/* <Divider className="divide" /> */}
                <div className="crow">
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={pname} label="Бүтээгдхүүний нэр" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "25%", paddingRight: "10px" }}>
                        <TextField inputRef={psize} label="Хэмжээ" type="number" fullWidth ></TextField>
                    </div>
                    <div style={{ width: "25%", paddingRight: "10px" }}>
                        <TextField inputRef={punit} label="Нэгж  KG/L" fullWidth ></TextField>
                    </div>
                </div>
                {/* <Divider className="divide" /> */}
                <div className="crow">
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        {/* <TextField inputRef={pnuminbox} label="Brand" type="number" fullWidth ></TextField> */}
                        <BrandFilter token={token} handleBrand={setBrand} brandid={brand} />
                    </div>

                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={pnuminbox} label="Хайрцаг дахь тоо" type="number" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={pdesc} label="Тайлбар" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    {localStorage.getItem('role').includes('114') ? 
                    <div style={{ width: "25%", paddingRight: "10px" }}>
                        <TextField inputRef={pprice} label="Үнэ" type="number"  fullWidth ></TextField>
                    </div> :  <div style={{ width: "25%", paddingRight: "10px" }}>
                        <TextField inputRef={pprice} label="Үнэ" type="number" disabled fullWidth ></TextField>
                    </div>
                    }
                    {localStorage.getItem('role').includes('115') ? 
                    <div style={{ width: "25%", paddingRight: "10px" }}>
                        <TextField inputRef={puld} label="Үлдэгдэл" type="number" fullWidth ></TextField>
                    </div> : <div style={{ width: "25%", paddingRight: "10px" }}>
                        <TextField inputRef={puld} label="Үлдэгдэл" type="number" disabled fullWidth ></TextField>
                    </div>
                    }
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={ptas} label="Тасарсан эсэх Y/N " fullWidth ></TextField>
                    </div>

                </div>
                <div className="crow">

                    {/* <div style={{ width: "60%", paddingRight: "10px" }}>
                        <TextField inputRef={pcre} label="Publication " fullWidth ></TextField>
                    </div> */}
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={pbonusp} label="Урамшуулал хувь" fullWidth type="number" ></TextField>
                    </div>
                    <div style={{ width: "50%", paddingRight: "10px" }}>
                        <TextField inputRef={pbonusu} label="Урамшуулалын тоо (дээш)" fullWidth type="number" ></TextField>
                    </div>
                </div>

            </div>
            <div className="mfooter">
                <Button onClick={handleSubmit}>Save</Button>
                <Button onClick={handleClick}>Cancel</Button>
                {/* <button>Save</button> */}
                {/* <button>Cancel</button> */}
            </div>
            {prodload ?
                <div className="prodload">
                    <CircularProgress />
                </div> : ""}
        </div>
    </div>
}

export default ProductModal;