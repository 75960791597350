import { TextField } from '@mui/material';
import React, { useState, useRef } from 'react';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import "../css/login.css"

const Login = ({ setLogin, setToken }) => {
    const unameref = useRef(null);
    const pwdref = useRef(null);
    const bref = useRef(null);
    const [pwdSee, setpwdSee] = useState(true);

    // const ref = useRef(initialValue)

    const keyDownCustUname = (e) => {

        if (e.keyCode == 13) {
            // console.log("Enter daragdsan");
            // console.log(unameref.current.value);
            pwdref.current.focus();
        }
    }

    const keyDownCustPwd = (e) => {

        if (e.keyCode == 13) {
            // console.log("Enter daragdsan");
            // console.log(unameref.current.value);
            bref.current.focus();
        }
    }

    const handleChange = (e) => {
        e.preventDefault();
        // console.log(unameref.current.value);
        checkLogin(unameref.current.value, pwdref.current.value);

    }

    const handlePwd = () => {
        // console.log("test: " + pwdSee);
        if (pwdSee) {
            setpwdSee(false);
        }
        else {
            setpwdSee(true);
        }
    }


    const checkLogin = (username, password) => {
        console.log(username + " : " + password);
        // console.log(JSON.stringify({ username, password }));
        const request = new Request('https://wms-cloud.com/api/authenticate', {
        //const request = new Request('http://localhost:8080/authenticate', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            //body: JSON.stringify({ "username": "E100", "password": "Mono123#" }),
            headers: new Headers({ 'Content-Type': 'application/json' })
        });
        fetch(request).then((response) => {
            if (response.status == 200) {
                return response.json()
                // setLogin(true);
            }
        }).then((res) => {
            console.log(res);
            // localStorage.setItem('uname', username);
            // console.log("setting: " + JSON.stringify(localStorage.getItem('uname')));
            // localStorage.setItem('pwd', password);
            
            localStorage.setItem('tok', res.token);
            localStorage.setItem('uname', res.userid);
            localStorage.setItem('role', res.role);
            localStorage.setItem('localbarcode',"");
            localStorage.setItem('localemp_id',"");
            localStorage.setItem('localzahdugaar',"");
            localStorage.setItem('localstat',"");
            localStorage.setItem('localCustidSelect',"Customer");
            //localStorage.setItem('localstartdate', undefined);
            //localStorage.setItem('localenddate', undefined);
            setToken(res.token);
        });
    }

    return <div className="logincont">
        <div className="headback"></div>
        <div className="svgcont">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#0d4c92" fillOpacity="1" d="M0,128L40,106.7C80,85,160,43,240,42.7C320,43,400,85,480,128C560,171,640,213,720,197.3C800,181,880,107,960,112C1040,117,1120,203,1200,245.3C1280,288,1360,288,1400,288L1440,288L1440,0L1400,0C1360,0,1280,0,1200,0C1120,0,1040,0,960,0C880,0,800,0,720,0C640,0,560,0,480,0C400,0,320,0,240,0C160,0,80,0,40,0L0,0Z"></path>
            </svg>
        </div>
        <div className="contbor">
            <div className="conthead">
                WMS
            </div>
            <div className="ccontlogin">
                <TextField inputRef={unameref} className="uname" label="Нэвтрэх нэр" onKeyDown={keyDownCustUname} />
                <TextField inputRef={pwdref} className="pwd" label="Нууц үг"
                    type={pwdSee ? "password" : ""}
                    InputProps={{
                        endAdornment: (
                            <div onClick={() => handlePwd()}>
                                <RemoveRedEyeIcon style={{ color: "gray" }} />
                            </div>
                        )
                    }}
                    onKeyDown={keyDownCustPwd} />
                {/* <input className="uname" enterKeyHint="Username" /> */}
                {/* <input className="pwd" /> */}
                <button ref={bref} onClick={handleChange} className="submit" >
                    Нэвтрэх
                </button>
            </div>
        </div>
        <div
            className="ctag">
            @WMSSystems
        </div>
    </div>
}

export default Login;