import React, { useState, useEffect } from 'react';
import CustTable from '../comp/custtable/custtable';
import * as XLSX from 'xlsx';
import { Button } from '@mui/material';
import { format } from 'date-fns';

const OrderRep = ({ token }) => {
    const [data, setData] = useState([]);
    //    const [exportdata, setExport] = useState([]);
    const [allcount, setAllCount] = useState([]);
    const [loading, setLoad] = useState(true);
    const [subrow, setSubrow] = useState(false);
    const [selectedData, setselectedData] = useState([]);

    const columns = [
        { field: 'prod_code', headerName: 'Баркод', width: 170  },
        { field: 'prod_comp_id', headerName: 'Компани код', },
        { field: 'prod_name', headerName: 'Бүтээгдхүүний нэр', width: 200  },
        { field: 'prod_size', headerName: 'Хэмжээ', },
        { field: 'prod_num_in_box', headerName: 'Хайрцаг тоо', },
        { type: 'number', field: 'prod_price', headerName: 'Үнэ', },
        { field: 'prod_uldegdel', headerName: 'Агуулах дахь үлдэгдэл', },
        { field: 'niit_too', headerName: 'Захиалгаас нэгтгэсэн тоо', },
        { field: 'zah_too', headerName: 'Захиалах тоо', },
        { type: 'number', field: 'sum_dun', headerName: 'Нийт үнэ', },
        { field: 'prod_tasarsan_eseh', headerName: 'Тасарсан эсэх', },
        { field: 'prod_rcre_time', headerName: 'Огноо', },
        { field: 'prod_unit', headerName: 'Нэгж', },
    ];

    useEffect(() => {
        const request = new Request('https://wms-cloud.com/api/OrderReportBalance?_start=0&_end=10', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        // console.log("token: " + token);
        console.log(
            JSON.stringify(request)
        );
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    // console.log(response.headers.get('X-Total-Count'));
                    // setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            // console.log(JSON.stringify(resp));
            setData(resp);
            setLoad(false);

        });
    }, []);

    const DeleteBulk = () => {
        setLoad(true);
        var btoken = localStorage.getItem('tok');
        for(var i=0;i<selectedData.length;i++)
        {
            console.log("delete barcode for: "+ selectedData[i].prod_code);

            const request = new Request('https://wms-cloud.com/api/OrderReportBalance/'+selectedData[i].prod_code, {
            method: 'DELETE',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${btoken}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
                })
            });

            console.log(
                JSON.stringify(request)
            );

            fetch(request).then(
                (response) => {
                    console.log("response: " + JSON.stringify(response));
                    if (response.status == 200) {
                        return response.json();
                    } else if (response.status == 500) {
                        alert("Алдаа гарлаа дахин оролдоно уу.");
                    } else {
                        localStorage.removeItem('tok');
                        window.location.reload(false);
                        alert("Алдаа гарлаа.")
                    }
                }
            ).then((resp) => {
                console.log("resp: " + JSON.stringify(resp));
            });
        }
        setLoad(false);
        window.location.reload(false);
    }

    const downloadExcel = () => {
        setLoad(true);
        var exportdata = [];
        var btoken = localStorage.getItem('tok');
        const request = new Request('https://wms-cloud.com/api/OrderReportBalance?_start=0&_end=5000', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${btoken}`,
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        console.log(
            JSON.stringify(request)
        );
        // console.log("token: " + token);
        fetch(request).then(
            (response) => {
                console.log("response: " + JSON.stringify(response));
                if (response.status == 200) {
                    // console.log(response.headers.get('X-Total-Count'));
                    // setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log("resp: " + JSON.stringify(resp));
            console.log("resp body:" + resp);
            // console.log(format(new Date(), 'yyyy MM dd hh:mm:ss')
            // );

            var currdate = format(new Date(), 'yyyy_MM_dd_hhmmss');


             const workSheet = XLSX.utils.json_to_sheet(resp);
             const workBook = XLSX.utils.book_new();
             XLSX.utils.book_append_sheet(workBook, workSheet, "Sheet");
            // //Buffer
             let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
            // //Binary string
             XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
            // //Download
             XLSX.writeFile(workBook, "OrderReport" + currdate + ".xlsx");

            setLoad(false);

        });

    };
    return <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className="parenthead">
            <div>
                <h3>Захиалгын тайлан</h3>
            </div>
            <div style={{ display: "flex" }}>
                <Button style={{ marginRight: "3px", height: "40px" }} onClick={downloadExcel} variant="outlined">Excel-ээр авах</Button>
            </div>

        </div>
        {subrow ?
            <div style={{ height: "45px", border: "1px solid black", marginBottom: "5px", border: "1px solid rgba(224, 224, 224, 1)", display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "5px", borderRadius: "5px" }}>
                <Button variant="outlined" color="error" onClick={DeleteBulk} >
                    Захиалгаас бүгдийг устгах
            </Button>
            </div> : <></>}
        <div className="tablecont">
            <CustTable
                name={"Order Report"}
                exportname={"order_report"}
                columns={columns}
                items={data}
                setselect={setselectedData}
                rowid={(row) => "ordrep" + row.id}
                allcont={allcount}
                loading={loading}
                cellname={"prod_name"}
                checkboxselect={true}
                subrow={subrow}
                setSubrow={setSubrow}
            // handlePage={}
            />
        </div>
    </div>
}

export default OrderRep;