import { useState, useEffect, useMemo } from 'react';
import '../css/pagination.css';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Button } from '@mui/material';

const Pagination = ({ totalItems, perpage, handlePage }) => {
    let pages = [];
    const max = Math.ceil(totalItems / perpage);
    const range = 10;
    // const [max, setMax] = useState(0);
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(10);
    const [curr, setCurr] = useState(0);

    console.log(max);



    const clickNext = () => {
        if (end + 5 < max) {
            setStart(prev => prev + 5);
            setEnd(prev => prev + 5);

        } else {
            setStart(prev => prev + (max - end));
            setEnd(prev => prev + (max - end));
        }
    }

    const clickPrev = () => {
        if (start > 1) {
            setStart(prev => prev - 5);
            setEnd(prev => prev - 5);
        } else {
            setStart(0);
            setEnd(10);
        }
    }

    const movePage = (p) => {
        if (p - 5 > 1 && p + 5 < max) {
            setStart(p - 5);
            setEnd(p + 5);
        }

        if (p - 5 < 1) {
            setStart(0);
            setEnd(10);
        } else if (p + 5 >= max) {
            setStart(max - 10);
            setEnd(max);
        }
    }

    const pageClick = (p) => {
        // setCurr(p);
        movePage(p);
        handlePage(p);
    }



    if (max < 10) {
        for (var i = 0; i < max; i++) {
            pages.push(i);
        }
    } else {
        for (var i = start; i < end; i++) {
            pages.push(i);
        }
    }


    return <div className="pagecont">
        <ul>

            <li onClick={() => clickPrev()} ><ArrowBackIosIcon /></li>
            <li onClick={() => movePage(0)} ><button style={{ fontSize: "10px", paddingTop: "3px" }}>Эхлэл</button></li>
            {pages.map(e =>
                <li key={e} onClick={() => pageClick(e)}><div>{e + 1}</div></li>
            )}
            <li onClick={() => movePage(max - 1)} ><button style={{ fontSize: "10px" }}>Төгсгөл</button></li>
            <li onClick={() => clickNext()}><ArrowForwardIosIcon /></li>
        </ul>
    </div>



}

export default Pagination;