import { Button, CircularProgress, Divider,  TextField } from '@mui/material';
import { useRef, useEffect, useState } from 'react';
import Companydown from '../comp/companydown';
import CustomerNameAuto from '../comp/custnameauto';
import '../css/Bonusmod.css'


const BonusAdd = ({ handleClose, token, open, custrow, handleModal, handleRefresh }) => {

    const [compid, setComp] = useState("");
    const [selected, setSelected] = useState({ cust_id: "Customer", cust_name: "Customer" });
    const [options, setOptions] = useState([]);
    const bo_prod_desc = useRef();
    const bo_bonus_prcnt = useRef();
    const [prodload, setProdload] = useState(false);
    

    const handleEscape = (e) => {
        if (e.keyCode == 27) {
            handleClose(false);
        }
    }

    useEffect(() => {
        window.addEventListener('keyup', handleEscape);
        return () => {
            window.removeEventListener('keyup', handleEscape);
        }
    }, [handleEscape])

    const handleClick = () => {
        if (open) {
            handleModal(false);
        }
    }

    const handleSubmit = () => {
        setProdload(true);
        var req = {
            'bo_comp_id': compid,
            'bo_cust_id': selected.cust_id,
            'bo_prod_desc': bo_prod_desc.current.value,
            'bo_bonus_prcnt': bo_bonus_prcnt.current.value
        }

        console.log(JSON.stringify(req));

        const request = new Request('https://wms-cloud.com/api/Bonus' , {
        //const request = new Request('http://localhost:8080/Bonus' , {
            method: 'POST',
            body: JSON.stringify(req),
            headers: new Headers({ 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${token}` })
        });

        fetch(request).then((response) => {
            if (response.status == 200) {
                console.log(response);
                setProdload(false);
                handleClose(false);


            } else {
                console.log("aldaa garsan" + JSON.stringify(response));
                alert("Амжилтгүй "+ JSON.stringify(response) );
                setProdload(false);

            }
            return response.json();
        }).then((resp) => {


                alert(resp.bo_prod_desc);
                handleRefresh();

        });
    }

    const getdataforname = (e) => {
            var tok = localStorage.getItem("tok");
            var url = "";
            if (e == null) {
                url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + selected.cust_name;
    
            } else {
                url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + e.target.value;
            }
            const request = new Request(url, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${tok}`,
                    'Access-Control-Allow-Headers': 'X-Total-Count'
                })
            });
    
            fetch(request).then(
                (response) => {
                    if (response.status == 200) {
                        return response.json();
                    } else if (response.status == 500) {
                        alert("Алдаа гарлаа дахин оролдоно уу.");
                    } else {
                        localStorage.removeItem('tok');
                        window.location.reload(false);
                        alert("Алдаа гарлаа.")
                    }
                }
            ).then((resp) => {
                var temp = [];
                resp.map((e) => {
                    temp.push({ "cust_id": e.cust_id, "cust_name": e.cust_name });
                });
                setOptions(temp);
            });
        }
    

    return <div className="custmodal" >
        <div className="mcont">
            <div className="mheader">
                Bonus Нэмэх
            <button onClick={() => handleClick()}>x</button>
            </div>
            <Divider />
            <div className="mccont">
                <div className="crow">
                    <div style={{ width: "100%", paddingRight: "10px"  }}>
                        <Companydown token={token} handleComp={setComp} />
                    </div>
                </div>
                <div className="crow">
                    <div style={{  width: "100%", paddingRight: "10px" }}>
                        <CustomerNameAuto selected={selected} setProd={setSelected} options={options} getdataforbar={getdataforname}/>
                    </div>
                </div>
                <div className="crow">
                    <div style={{  width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={bo_prod_desc} label="Description" fullWidth ></TextField>
                    </div>
                </div>
                <div className="crow">
                    <div style={{  width: "100%", paddingRight: "10px" }}>
                        <TextField inputRef={bo_bonus_prcnt} type="number" label="Bonus Percent" fullWidth ></TextField>
                    </div>
                </div>
                
            </div>
            <div className="mfooter">
                <Button onClick={handleSubmit}>Хадгалах</Button>
                <Button onClick={handleClick}>Буцах</Button>
            </div>
            {prodload ?
                <div className="prodload">
                    <CircularProgress />
                </div> : ""
            }
        </div>
    </div>

}

export default BonusAdd;