import React, { useState, useEffect } from 'react';
import CustTable from '../comp/custtable/custtable';
import { Button, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import CustomerNameAuto from '../comp/custnameauto';

const LoanHistory = ({ token }) => {

    const [items, setItems] = useState([]);
    const [loading, setLoad] = useState(true);
    const [allcount, setAll] = useState(0);
    const [selected, setSelected] = useState({ cust_id: "Customer", cust_name: "Customer" });
    const [options, setOptions] = useState([]);
    const [stat, setStat] = useState("");
    const [[start, end], setSend] = useState([0, 20]);

    const columns = [
        { field: 'id', headerName: 'Дугаар', },
        { field: 'la_cust_name', headerName: 'Харилцагч', width: 250 },
        { type: 'number', field: 'la_balance', headerName: 'Зээлийн дүн', },
        { type: 'number', field: 'la_paid_amount', headerName: 'Төлсөн дүн', },
        { field: 'la_status', headerName: 'Төлөв', width: 150 },
        { field: 'la_tulsun_helber', headerName: 'Төлсөн хэлбэр', width: 120  },
        { field: 'la_emp_id', headerName: 'Борлуулагч', },
        { field: 'la_ord_id', headerName: 'Захиалгын дугаар', width: 135  },
        { field: 'la_ord_date', headerName: 'Захиалгын огноо', width: 135  },
    ]

    useEffect(() => {
        var url = 'https://wms-cloud.com/api/Loanhistory?_start=' + start + '&_end=' + end ;
        //var url = 'http://localhost:8080/Loanhistory?_start=' + start + '&_end=' + end ;

        if (selected.cust_id != "Customer") {
            url = url + '&cust_id=' + selected.cust_id;
        }
        if (stat != "") {
            url = url + "&la_status=" + stat;
        }

        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        // console.log("token: " + token);
        console.log(
            JSON.stringify(request)
        );
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    // console.log("allcoubt : " + response.headers.get('X-Total-Count'));
                    setAll(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log(JSON.stringify(resp));
            setItems(resp);
            setLoad(false);
        });
    }, [selected,stat,start,end]);

    const handlePage = (e) => {
        setSend([e * 20, (e * 20 + 20)]);
    }

    const handleChange = (e) => {
        setLoad(true);
        setStat(e.target.value);
    }

    const getdataforname = (e) => {
        // console.log("changevallen" + e.target.value);
        // if (e.target.value.length > 0) {
        var tok = localStorage.getItem("tok");
        var url = "";
        if (e == null) {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + selected.cust_name;

        } else {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + e.target.value;
        }
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&prod_code=' + e.target.value;
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=5000';
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });

        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log("from here 2");
            // console.log(JSON.stringify(resp));
            var temp = [];
            resp.map((e) => {
                temp.push({ "cust_id": e.cust_id, "cust_name": e.cust_name });
            });
            // console.log("temp:" + JSON.stringify(temp));
            setOptions(temp);
        });
        // }
    }

    return <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className="parenthead">
            <h3>
                Зээлийн түүх
            </h3>

        <div style={{ display: "flex" }}>
        <div style={{ width: "200px", marginRight: "3px" }}>
                    {/* <TextField style={{ marginLeft: "3px" }} inputRef={searchcust_id}  size="small" label="Харилцагч" onKeyDown={cust_idChange} /> */}   
                    <CustomerNameAuto selected={selected} setProd={setSelected} options={options} getdataforbar={getdataforname}/>
                </div>
                <div style={{ width: "120px" }}>
                    {/* <TextField style={{ marginLeft: "3px" }} size="small" label="Status" onKeyDown={() => { }} /> */}
                    <FormControl fullWidth size="small" >
                        <InputLabel id="demo-simple-select-label">Төлөв</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={stat}
                            label="Status"
                            onChange={handleChange}
                        >
                            {/* {items.map(e => <MenuItem key={e.id} value={e.id}>{e.brand_name}</MenuItem>)} */}
                            <MenuItem key={"Бүгд"} value={""}>{"Бүгд"}</MenuItem>
                            <MenuItem key={"Төлөгдсөн"} value={"Төлөгдсөн"}>{"Төлөгдсөн"}</MenuItem>)
                            <MenuItem key={"Төлөгдөөгүй"} value={"Төлөгдөөгүй"}>{"Төлөгдөөгүй"}</MenuItem>)
                        </Select>
                    </FormControl>
                </div>
                <Button style={{ marginLeft: "3px", height: "40px" }} variant="outlined" onClick={() => {
                    // setStat("");
                    window.location.reload(false);
                    
                }}> <RefreshIcon /> </Button>
        </div>
        </div>
        <div className="tablecont">
            <CustTable
                name={"Loan History"}
                columns={columns}
                items={items}
                handlePage={handlePage}
                rowid={(row) => 'lhist' + row.id}
                loading={loading}
                allcont={allcount}
            />
        </div>
    </div>
}

export default LoanHistory;