import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ProductMod = ({ }) => {
    const location = useLocation();

    return <div>
        Product Mod
        {location.state.item.prod_code}

    </div>
}

export default ProductMod;