import React, { useState, useEffect, useRef, useLocalStorage } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
// import 'react-data-grid/lib/styles.css';
import DataGrid from 'react-data-grid';
import CustTable from '../comp/custtable/custtable';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';
import dayjs from 'dayjs';
import { format } from 'date-fns';
import * as XLSX from 'xlsx';
import Stack from '@mui/material/Stack';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import CustomerNameAuto from '../comp/custnameauto';
import streamSaver from 'streamsaver';

const Order = ({ token }) => {
    const [items, setItems] = useState([]);
    const [[start, end], setSend] = useState([0, 20]);
    const [allcont, setAllCount] = useState(0);
    const [stat, setStat] = useState("");
    const [loading, setLoad] = useState(true);
    const [barcode, setBarcode] = useState("");
    const [startdate, setStartDate] = React.useState(dayjs());
    const [actstart, setActStart] = React.useState();
    const [enddate, setEndDate] = React.useState(dayjs());
    const [actend, setActEnd] = React.useState();
    const [emp_id, setEmp_id] = useState("");
    const [subrow, setSubrow] = useState(false);
    const [zahdugaar, setZahdugaar] = useState("");
    const [selected, setSelected] = useState({ cust_id: "Customer", cust_name: "Customer" });
    const [options, setOptions] = useState([]);
    const [selectedData, setselectedData] = useState([]);

    const navigate = useNavigate();

    const searchbarcode = useRef();
    const searchemp_id = useRef();
    const searchzahdugaar = useRef();


    useEffect(() => {
        console.log(localStorage.getItem('localstartdate'));

        if(localStorage.getItem('localbarcode')!="" && barcode=="")
        {
            setBarcode(localStorage.getItem('localbarcode'));
            searchbarcode.current.value=localStorage.getItem('localbarcode');
        }
        if(localStorage.getItem('localemp_id')!="" && emp_id=="")
        {
            setEmp_id(localStorage.getItem('localemp_id'));
            searchemp_id.current.value=localStorage.getItem('localemp_id');
        }
        if(localStorage.getItem('localzahdugaar')!="" && zahdugaar=="")
        {
            setZahdugaar(localStorage.getItem('localzahdugaar'));
            searchzahdugaar.current.value=localStorage.getItem('localzahdugaar');
        }
        if(localStorage.getItem('localstat')!="" && stat=="")
        {
            setStat(localStorage.getItem('localstat'));
        }
        if(localStorage.getItem('localCustidSelect')!="Customer" && selected.cust_id=="Customer")
        {
            setSelected({ cust_id: localStorage.getItem('localCustidSelect')});
        }
        

        localStorage.setItem('localbarcode',barcode);
        localStorage.setItem('localemp_id',emp_id);
        localStorage.setItem('localzahdugaar',zahdugaar);
        localStorage.setItem('localstat', stat);
        localStorage.setItem('localCustidSelect',  selected.cust_id);
      
        var url = 'https://wms-cloud.com/api/Orders?_start=' + start + '&_end=' + end ;
        if (stat != "") {
            url = url + "&status=" + stat;
        }
        if (barcode != "") {
            url = url + "&barcode=" + barcode;
        }
        if (emp_id != "") {
            url = url + "&emp_id=" + emp_id;
        }
        if (zahdugaar != "") {
            url = url + "&zahdugaar=" + zahdugaar;
        }
        if (selected.cust_id != "Customer") {
            url = url + '&cust_id=' + selected.cust_id;
        }
        if (actstart != undefined) {
            // console(format(startdate, 'yyyy-MM-DD'));
            url = url + '&from_date=' + actstart;
            
        }

        if (actend != undefined) {
            url = url + '&to_date=' + actend;
            
        }
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        console.log("token: " + token);
        console.log(
            JSON.stringify(request)
        );
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    console.log(response.headers.get('X-Total-Count'));
                    setAllCount(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log(resp);
            setItems(resp);
            setLoad(false);
        });


    }, [start, end, stat, barcode, emp_id, zahdugaar, selected, startdate, enddate])


    const getdataforname = (e) => {
        // console.log("changevallen" + e.target.value);
        // if (e.target.value.length > 0) {
        var tok = localStorage.getItem("tok");
        var url = "";
        if (e == null) {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + selected.cust_name;

        } else {
            url = 'https://wms-cloud.com/api/Customers?_start=0&_end=20&cust_name=' + e.target.value;
        }
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=50&prod_code=' + e.target.value;
        // var url = 'https://wms-cloud.com/api/Products?_start=0&_end=5000';
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });

        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    return response.json();
                } else if (response.status == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');

                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {
            console.log("from here 2");
            // console.log(JSON.stringify(resp));
            var temp = [];
            resp.map((e) => {
                temp.push({ "cust_id": e.cust_id, "cust_name": e.cust_name });
            });
            // console.log("temp:" + JSON.stringify(temp));
            setOptions(temp);
        });
        // }
    }

    const columns = [
        { field: 'id', headerName: 'Зах/дугаар', editable: false },
        { field: 'ord_cust_id', headerName: 'Харилцагч', minWidth: 300, maxWidth: 500 , editable: false },
        { field: 'ord_date', headerName: 'Зах/огноо', editable: false },
        { field: 'ord_emp_id', headerName: 'Борлуулагч', editable: false },
        { field: 'ord_status', headerName: 'Төлөв', minWidth: 200, maxWidth: 500 , editable: false },
        { type: 'number', field: 'ord_sum_amount', headerName: 'Нийт дүн', editable: false },
        { field: 'ord_cre_time', headerName: 'Үүссэн огноо', editable: false },
        { field: 'ord_tuluh_helber', headerName: 'Төлөх хэлбэр', editable: false },

    ];

    const handlePage = (e) => {
        setSend([e * 20, (e * 20 + 20)]);
    }

    const handleRowClick = (e) => {
        console.log(e);
        // navigate.setParams({ row: e });
        // navigate('/OrderMod');
        navigate('/OrderMod', { state: { row: e, ordid: e.ordid } });
    }

    function rowKeyGetter(row) {
        return row.id;
    }

    const handleChange = (e) => {
        setLoad(true);
        setStat(e.target.value);
    }

    const handleStartChange = (e) => {
        setLoad(true);
        var t = new Date(e);
        var d = format(t, 'yyyy-MM-dd');
        console.log("e: " + e + " : " + t + " : " + d);
        setStartDate(e);
        setActStart(d);
    }

    const handleEndChange = (e) => {
        setLoad(true);
        var t = new Date(e);
        var d = format(t, 'yyyy-MM-dd');
        setEndDate(e);
        setActEnd(d);
    }

    const barcodeChange = (e) =>{
        console.log("barcode ruu orj baina");
        if (e.keyCode == 13) {
        setBarcode(searchbarcode.current.value);
        }
    }
    const emp_idChange = (e) =>{
        if (e.keyCode == 13) {
        setEmp_id(searchemp_id.current.value);
        }
    }
    const zahdugaarChange = (e) =>{
        if (e.keyCode == 13) {
        setZahdugaar(searchzahdugaar.current.value);
        }
    }

    
    const SelectedDailyReport = () => {
        console.log("selectedvalues: "+JSON.stringify(selectedData));
        var selectedId="";
        for(var i=0;i<selectedData.length;i++)
        {
            if(i+1==selectedData.length)
            {
                selectedId=selectedId+selectedData[i].id; 
            }
            else
            {
                selectedId=selectedId+selectedData[i].id+",";
            }
            
        }
        console.log(selectedId);
        var tok = localStorage.getItem("tok");
        var url= `https://wms-cloud.com/api/OrderSelectedReport/${selectedId}`;
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        
        
        fetch(request).then(
            (response) => {
                if (response.status == 200) {
                    console.log('status 200');
                    // console.log(response.headers.get('X-Total-Count'));
                    return response.json();
                } else if (response.state == 500) {
                    alert("Алдаа гарлаа дахин оролдоно уу.");
                } else {
                    // localStorage.getItem('tok')
                    localStorage.removeItem('tok');
                    window.location.reload(false);
                    alert("Алдаа гарлаа.")
                }
            }
        ).then((resp) => {

            const workSheet = XLSX.utils.json_to_sheet(resp);
            const workBook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workBook, workSheet, "report");
            //Buffer
            let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
            //Binary string
            XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
            //Download
            XLSX.writeFile(workBook, "OrderSelectedReport" + ".xlsx");

        });
    }

    const PadaanBulk = () => {
        console.log("selectedvalues: "+JSON.stringify(selectedData));
        var selectedId="";
        for(var i=0;i<selectedData.length;i++)
        {
            if(i+1==selectedData.length)
            {
                selectedId=selectedId+selectedData[i].id; 
            }
            else
            {
                selectedId=selectedId+selectedData[i].id+",";
            }
            
        }
        console.log(selectedId);
        var tok = localStorage.getItem("tok");
        var url= `https://wms-cloud.com/api/viewBulkPadaan/${selectedId}`;
        const request = new Request(url, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${tok}`,
                
                'Access-Control-Allow-Headers': 'X-Total-Count'
            })
        });
        
        
        fetch(request).then((response) => {
                //response 200 baina uu shalgaad aldaa hevlene
                if (response.status < 200 || response.status >= 300) {
                    console.log("Сервертэй холбогдоход алдаа гарлаа", { type: 'warning' });
                }
                else {
                    const fileStream = streamSaver.createWriteStream(`PadaanDetails${selectedId}.xlsx`);
                    const writer = fileStream.getWriter();

                    const reader = response.body.getReader();

                    const pump = () => reader.read()
                        .then(({ value, done }) => {
                            if (done) writer.close();
                            else {
                                writer.write(value);
                                return writer.ready.then(pump);
                            }
                        });

                    pump()
                        .then(() => console.log('Closed the stream, Done writing'))
                        .catch(err => console.log(err));

                }

            })
            .catch((e) => {
                console.error(e);
                console.log('Error: Aлдаа гарлаа', { type: 'warning' });
            });
    }


    return <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
        <div className="parenthead">
            <div>
                <h3>Захиалга</h3>
            </div>
            {subrow ?
            <div style={{ height: "45px", marginBottom: "5px", border: "1px solid rgba(224, 224, 224, 1)", display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "5px", borderRadius: "5px" }}>
                <Button variant="outlined" color="secondary" onClick={PadaanBulk} >
                    Олон падаан хэвлэх
            </Button>
            <Button variant="outlined" color="secondary" onClick={SelectedDailyReport} >
                    Бараа нэгтгэл тайлан
            </Button>
            </div> : <div style={{ display: "flex" }}>
                <Button style={{ marginLeft: "3px", height: "40px" }} variant="outlined" onClick={() => { navigate('/OrderAdd') } }> <AddIcon /> </Button>
                <div style={{ width: "190px" }}>
                    <TextField style={{ marginLeft: "3px" }} inputRef={searchbarcode}  size="small" label="Баркод"  onKeyDown={barcodeChange} />
                </div>
                <div style={{ width: "150px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Эхлэх огноо"
                                inputFormat="YYYY-MM-DD"
                                value={startdate}
                                onChange={handleStartChange}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </div>
                <div style={{ width: "10px" }}></div>
                <div style={{ width: "150px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Stack spacing={3}>
                            <DesktopDatePicker
                                label="Дуусах огноо"
                                inputFormat="YYYY-MM-DD"
                                value={enddate}
                                onChange={handleEndChange}
                                renderInput={(params) => <TextField size="small" {...params} />}
                            />
                        </Stack>
                    </LocalizationProvider>
                </div>
                <div style={{ width: "200px", marginRight: "3px" }}>
                    {/* <TextField style={{ marginLeft: "3px" }} inputRef={searchcust_id}  size="small" label="Харилцагч" onKeyDown={cust_idChange} /> */}   
                    <CustomerNameAuto selected={selected} setProd={setSelected} options={options} getdataforbar={getdataforname}/>
                </div>
                <div style={{ width: "120px", marginRight: "3px" }}>
                    <TextField style={{ marginLeft: "3px" }} inputRef={searchemp_id}  size="small" label="Борлуулагч" onKeyDown={emp_idChange} />
                </div>
                <div style={{ width: "120px" }}>
                    {/* <TextField style={{ marginLeft: "3px" }} size="small" label="Status" onKeyDown={() => { }} /> */}
                    <FormControl fullWidth size="small" >
                        <InputLabel id="demo-simple-select-label">Төлөв</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={stat}
                            label="Status"
                            onChange={handleChange}
                        >
                            {/* {items.map(e => <MenuItem key={e.id} value={e.id}>{e.brand_name}</MenuItem>)} */}
                            <MenuItem key={"Захиалга авсан"} value={"Захиалга авсан"}>{"Захиалга авсан"}</MenuItem>)
                            <MenuItem key={"Зарлага гаргасан"} value={"Зарлага гаргасан"}>{"Зарлага гаргасан"}</MenuItem>)
                            <MenuItem key={"Хүргэлт хийсэн"} value={"Хүргэлт хийсэн"}>{"Хүргэлт хийсэн"}</MenuItem>)
                            <MenuItem key={"Төлөгдсөн"} value={"Төлөгдсөн"}>{"Төлөгдсөн"}</MenuItem>)
                        </Select>
                    </FormControl>
                </div>
                <div style={{ width: "150px" }}>
                    <TextField style={{ marginLeft: "3px" }} inputRef={searchzahdugaar}  size="small" label="Баримт дугаар" onKeyDown={zahdugaarChange} />
                </div>

                <Button style={{ marginLeft: "3px", height: "40px" }} variant="outlined" onClick={() => {
                    // setStat("");
                    window.location.reload(false);
                    localStorage.setItem('localbarcode',"");
                    localStorage.setItem('localemp_id',"");
                    localStorage.setItem('localzahdugaar',"");
                    localStorage.setItem('localstat', "");
                    localStorage.setItem('localCustidSelect',  "Customer");
                    
                }}> <RefreshIcon /> </Button>
            </div>}
            
        </div>

        <div className="tablecont">
            <CustTable
                name={"Orders"}
                items={items}
                columns={columns}
                allcont={allcont}
                handlePage={handlePage}
                rowClick={handleRowClick}
                perpage={20}
                range={10}
                loading={loading}
                rowid={(row) => 'ord' + row.id} 
                checkboxselect={true}
                setselect={setselectedData}
                subrow={subrow}
                setSubrow={setSubrow}
                />
        </div>
    </div>
}

export default Order;